import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Spinner, useToast } from "@chakra-ui/react";
import Panel from "../../../../components/shared/Panel";
import { primary, newBorder } from "../../../../utils/colors";
import StatusButton from "../../../../components/buttons/StatusButton";
import SinglePageTopBar from "../../../../components/layout/SinglePageTopBar";
import { useTranslation } from "react-i18next";
import MoreButton from "../../../../components/buttons/MoreButton";
import {
  getImplantFailureReportById,
  updateImplantFailureReportStatus,
  deleteImplantFailureReport,
} from "../../../../actions/reportActions";
import dayjs from "dayjs";
import ConfirmRejectModal from "../../../../components/modals/ConfirmRejectModal";
import ConfirmApproveModal from "../../../../components/modals/ConfirmApproveModal";
import ConfirmClosedModal from "../../../../components/modals/ConfirmClosedModal";
import {
  REPORT_IMPLANT_FAILURE_UPDATE_STATUS_RESET,
  REPORT_IMPLANT_FAILURE_DELETE_RESET,
} from "../../../../constants/reportConstants";
import ReportClientInfo from "./partials/ReportClientInfo";
import ReportInfoBlocks from "./partials/ReportInfoBlocks";
import ReportActions from "./partials/ReportActions";
import ReportCreatedInfo from "./partials/ReportCreatedInfo";
import { TbFileCheck, TbFileX } from "react-icons/tb";

const SingleImplantFailureReport = () => {
  // GENERAL
  const id = useParams().id;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();

  // STATE
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [documentNumber, setDocumentNumber] = useState("");

  // REDUX
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const reportImplantFailureDetailsReducer = useSelector(
    (state) => state.reportImplantFailureDetails
  );

  const {
    error: errorImplantFailureReportDetails,
    loading: loadingImplantFailureReportDetails,
    report,
  } = reportImplantFailureDetailsReducer;

  const reportImplantFailureUpdateStatus = useSelector(
    (state) => state.reportImplantFailureUpdateStatus
  );

  const {
    error: errorImplantFailureUpdateStatus,
    loading: loadingImplantFailureUpdateStatus,
    success: successImplantFailureUpdateStatus,
  } = reportImplantFailureUpdateStatus;

  const reportImplantFailureDelete = useSelector(
    (state) => state.reportImplantFailureDelete
  );
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = reportImplantFailureDelete;

  // HANDLERS

  const rejectReportHandler = () => {
    const status = "rejected";
    console.log("id", id);
    console.log("userInfo._id", userInfo._id);
    console.log("cancelReason", cancelReason);
    console.log("status", status);

    if (cancelReason === "other") {
      dispatch(
        updateImplantFailureReportStatus(id, userInfo._id, otherReason, status)
      );
    } else {
      dispatch(
        updateImplantFailureReportStatus(id, userInfo._id, cancelReason, status)
      );
    }
  };

  const approveReportHandler = () => {
    const status = "approved";
    dispatch(
      updateImplantFailureReportStatus(id, userInfo._id, cancelReason, status)
    );
  };

  const closeReportHandler = () => {
    const status = "closed";
    dispatch(
      updateImplantFailureReportStatus(id, userInfo._id, documentNumber, status)
    );
  };

  const deleteCourseHandler = () => {
    dispatch(deleteImplantFailureReport(id));
  };

  // USE EFFECT
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getImplantFailureReportById(id));
  }, [id, successImplantFailureUpdateStatus]);

  useEffect(() => {
    if (successImplantFailureUpdateStatus) {
      toast({
        title: t("implantFailureReports.reportUpdated"),
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      dispatch({ type: REPORT_IMPLANT_FAILURE_UPDATE_STATUS_RESET });
      dispatch(getImplantFailureReportById(id));
    } else if (errorImplantFailureUpdateStatus) {
      toast({
        title: t("implantFailureReports.error"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [successImplantFailureUpdateStatus, errorImplantFailureUpdateStatus]);

  useEffect(() => {
    if (successDelete) {
      toast({
        title: t("implantFailureReports.reportDeleted"),
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      dispatch({ type: REPORT_IMPLANT_FAILURE_DELETE_RESET });
      navigate("/admin/reports/implant-failure-reports");
    } else if (errorDelete) {
      toast({
        title: t("implantFailureReports.error"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [successDelete, errorDelete]);

  // LOGS
  // console.log("report", report);

  // RENDER
  return (
    <>
      {/* MODALS START */}
      {showCancelModal && (
        <ConfirmRejectModal
          report={report}
          isOpen={showCancelModal}
          onClose={() => setShowCancelModal(false)}
          confirmAction={() => {
            rejectReportHandler();
            setShowCancelModal(false);
          }}
          cancelReason={cancelReason}
          setCancelReason={setCancelReason}
          isTextArea
          otherReason={otherReason}
          setOtherReason={setOtherReason}
        />
      )}

      {showApproveModal && (
        <ConfirmApproveModal
          report={report}
          isOpen={showApproveModal}
          onClose={() => setShowApproveModal(false)}
          confirmAction={() => {
            approveReportHandler();
            setShowApproveModal(false);
          }}
          cancelReason={cancelReason}
          setCancelReason={setCancelReason}
          isTextArea
        />
      )}

      {showCloseModal && (
        <ConfirmClosedModal
          report={report}
          isOpen={showCloseModal}
          onClose={() => setShowCloseModal(false)}
          confirmAction={() => {
            closeReportHandler();
            setShowCloseModal(false);
          }}
          documentNumber={documentNumber}
          setDocumentNumber={setDocumentNumber}
        />
      )}
      {/* MODALS END */}

      {/* MAIN START */}
      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            {!loadingImplantFailureReportDetails ? (
              <>
                {/* HEADER START */}
                <SinglePageTopBar
                  backFunction={() => navigate(-1)}
                  title={t("implantFailureReports.singleTitle")}
                  type={report.trackingNumber}
                  titleAdditional={dayjs(report.createdAt).format("YYYY-MM-DD")}
                  //   loading={loading}
                >
                  <StatusButton
                    isUnclickable
                    currentStatus={report.status}
                    //  onUpdateStatus={handleUpdateStatus}
                    options="report"
                  />
                  <MoreButton
                    id={id}
                    isBig
                    deleteHandler={deleteCourseHandler}
                    deleteLabel={t("implantFailureReports.deleteReport")}
                    viewIcon={report.status === "pending" ? TbFileCheck : null}
                    // viewHandler={
                    //   report.status === "pending"
                    //     ? () => setShowApproveModal(true)
                    //     : null
                    // }
                    viewHandler={
                      report.status === "pending"
                        ? () => setShowApproveModal(true)
                        : report.status === "approved"
                        ? () => setShowCloseModal(true)
                        : null
                    }
                    viewLabel={
                      report.status === "pending"
                        ? t("implantFailureReports.approveExchange")
                        : report.status === "approved"
                        ? t("implantFailureReports.closeReport")
                        : null
                    }
                    editIcon={report.status === "pending" ? TbFileX : null}
                    editHandler={
                      report.status === "pending"
                        ? () => setShowCancelModal(true)
                        : null
                    }
                    editLabel={
                      report.status === "pending"
                        ? t("implantFailureReports.rejectExchange")
                        : null
                    }
                  />
                </SinglePageTopBar>
                {/* HEADER END */}

                {/* MAIN LAYOUT START */}
                <Flex w="100%" justify="space-between" p="3rem 2rem">
                  <Flex w="65%" p="0 2rem 0 0rem">
                    <ReportInfoBlocks data={report} />
                  </Flex>

                  <Flex
                    w="35%"
                    justify="flex-start"
                    borderLeft={`1px solid ${newBorder}`}
                    p="0 0rem 0 2rem"
                    direction="column"
                  >
                    <ReportClientInfo data={report} />
                    <ReportCreatedInfo data={report} />
                  </Flex>
                </Flex>
                {/* MAIN LAYOUT END */}

                {/* ACTIONS START */}
                <ReportActions
                  report={report}
                  setShowCancelModal={setShowCancelModal}
                  setShowApproveModal={setShowApproveModal}
                  setShowCloseModal={setShowCloseModal}
                />
                {/* ACTIONS END */}
              </>
            ) : (
              <Flex justify="center" align="center" w="100%" p="8rem 2rem">
                <Spinner size="xl" color={primary} />
              </Flex>
            )}
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
      {/* MAIN END */}
    </>
  );
};

export default SingleImplantFailureReport;
