import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  Heading,
  Flex,
  Image,
  Spinner,
  Button,
  ButtonGroup,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  backgroundLight,
  textPrimary,
  textSecondary,
  white,
  primary,
  buttonBlue,
  buttonBlueText,
  newBorder,
} from "../../../utils/colors";
import SearchBarMain from "./partials/SearchBarMain";
import { listPublicCourses } from "../../../actions/courseActions";
import CoursesPublicList from "./list/CoursesPublicList";
import EventCheckout from "./partials/EventCheckout";
import dayjs from "dayjs";
import lt from "dayjs/locale/lt";
import utc from "dayjs/plugin/utc.js";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import Header from "../../landing/layout/Header";
import useDebounce from "../../../hooks/useDebounce";
import noServices from "../../../assets/images/empty-box.png";
import Footer from "../../landing/layout/Footer";
import Faq from "../../general/partials/Faq";
import { useMediaQuery } from "react-responsive";

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);

const groupCoursesByMonth = (
  courses,
  currentDate = dayjs(),
  type = "upcoming",
  query = ""
) => {
  const grouped = {};
  query = query.toLowerCase();

  courses
    .filter((course) => {
      const matchesQuery = course.title.toLowerCase().includes(query);
      const courseStartDate = dayjs(course.startDate).utc();
      const isAfterCurrentDate = courseStartDate.isSameOrAfter(
        currentDate,
        "day"
      );

      return (
        matchesQuery &&
        (type === "upcoming" ? isAfterCurrentDate : !isAfterCurrentDate)
      );
    })
    .forEach((course) => {
      const courseStartDate = dayjs(course.startDate).utc();
      const monthYear = courseStartDate.format("MMMM YYYY");
      if (!grouped[monthYear]) {
        grouped[monthYear] = {
          courses: [],
          date: courseStartDate.startOf("day"),
        };
      }
      grouped[monthYear].courses.push(course);
    });

  // Sort groups by the date
  const sortedKeys = Object.keys(grouped).sort(
    (a, b) => grouped[a].date.valueOf() - grouped[b].date.valueOf()
  );

  // console.log("Sorted keys:", sortedKeys);

  return sortedKeys.reduce((acc, key) => {
    acc[key] = grouped[key];
    return acc;
  }, {});
};

const countCourses = (groupedCourses) =>
  Object.values(groupedCourses).reduce(
    (acc, group) => acc + group.courses.length,
    0
  );

const CoursesPublic = () => {
  // ID
  const { tenantId } = useParams();

  // MOBILE
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  // GENERAL
  const { t, i18n } = useTranslation();

  // STATE
  const [currentCourses, setCurrentCourses] = useState([]); // [1, 2, 3, 4, 5, 6, 7, 8, 9, 10
  const [isEventCheckoutOpen, setIsEventCheckoutOpen] = useState(false);

  const [selectedCourse, setSelectedCourse] = useState(null);

  const [viewType, setViewType] = useState("upcoming"); // 'upcoming' or 'past'
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  // Debounced search query to delay execution
  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  // REDUX
  const dispatch = useDispatch();

  const coursePublicList = useSelector((state) => state.coursePublicList);
  const { loading, error, courses } = coursePublicList;

  // HANDLERS
  const openCheckout = (course) => {
    setSelectedCourse(course); // Set the selected course
    setIsEventCheckoutOpen(true);
  };
  const closeCheckout = () => {
    setIsEventCheckoutOpen(false);
    setSelectedCourse(null);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setIsSearching(true);
  };

  const handleViewSwitch = (type) => setViewType(type);

  // USE EFFECT

  useEffect(() => {
    //scroll to top
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(listPublicCourses());
  }, []);

  useEffect(() => {
    if (courses) {
      console.log("Courses in useEffect:", courses);
      const groupedCourses = groupCoursesByMonth(
        courses,
        dayjs(),
        viewType,
        debouncedSearchQuery
      );
      console.log("Grouped courses:", groupedCourses);
      setCurrentCourses(groupedCourses);
      setIsSearching(false);
    }
  }, [courses, viewType, debouncedSearchQuery]);

  // CUSTOM
  const hasResults = Object.keys(currentCourses).length > 0;
  const resultsCount = countCourses(currentCourses);

  // LOGS
  // console.log("courses", courses);
  // console.log("currentCourses", currentCourses);

  return (
    <>
      <Helmet>
        <title>{t("pageTitle.events")}</title>
        <meta name="description" content={t("pageDescription.events")} />
      </Helmet>

      {isEventCheckoutOpen && (
        <EventCheckout
          isOpen={isEventCheckoutOpen}
          onClose={closeCheckout}
          course={selectedCourse} // Pass the selected course to EventCheckout
        />
      )}

      <Header />
      <Flex
        w="100%"
        direction="column"
        p={["80px 0 0", "80px 0 0", "100px 0 0"]}
      >
        <Flex
          w="100%"
          maxW={["100%", "100%", "1200px"]}
          p={["1.5rem 0rem 0", "1.5rem 0rem 0", "initial"]}
          direction="column"
          m={["0rem auto 0", "0rem auto 0", "4rem auto 0"]}
        >
          <Heading
            fontSize={["2rem", "2rem", "2.5rem"]}
            fontWeight="700"
            color="textPrimary"
            m={["0 0 0.5rem", "0 0 0.5rem", "0 0 1.5rem"]}
            p={["0 1.5rem", "0 1.5rem", "initial"]}
          >
            {t("publicCourses.title")}
          </Heading>
          <Flex
            w="100%"
            p="0 0rem"
            direction="column"
            m="0 auto"
            align="center"
            justify="center"
            maxW={["100%", "100%", "1200px"]}
            flexShrink="0"
          >
            <SearchBarMain
              value={searchQuery}
              onChange={handleSearch}
              placeholder={t("publicCourses.searchPlaceholder")}
              elements={
                isMobile ? null : (
                  <ButtonGroup
                    variant="solid"
                    isAttached
                    borderRadius="1rem"
                    m="0 0 0rem"
                    h="100%"
                  >
                    <Button
                      bg={
                        viewType === "upcoming" ? buttonBlue : backgroundLight
                      }
                      color={
                        viewType === "upcoming" ? buttonBlueText : textSecondary
                      }
                      onClick={() => handleViewSwitch("upcoming")}
                      fontSize="sm"
                      p="0.5rem 2rem"
                      borderTopLeftRadius="10rem"
                      borderBottomLeftRadius="10rem"
                    >
                      {t("publicCourses.coursesUpcoming")}
                    </Button>
                    <Button
                      bg={viewType === "past" ? buttonBlue : backgroundLight}
                      color={
                        viewType === "past" ? buttonBlueText : textSecondary
                      }
                      onClick={() => handleViewSwitch("past")}
                      fontSize="sm"
                      p="0.5rem 2rem"
                      borderRightRadius="10rem"
                      borderTopRightRadius="10rem"
                    >
                      {t("publicCourses.coursesPast")}
                    </Button>
                  </ButtonGroup>
                )
              }
            />
          </Flex>

          {isMobile && (
            <Flex w="100%" p="1rem 1.5rem 1rem">
              <Flex gap="1rem" w="100%">
                <Button
                  bg={viewType === "upcoming" ? primary : backgroundLight}
                  color={viewType === "upcoming" ? white : textSecondary}
                  onClick={() => handleViewSwitch("upcoming")}
                  fontSize="sm"
                  p="0.5rem 1rem"
                  borderRadius="1rem"
                  _hover={{
                    bg: viewType === "upcoming" ? primary : backgroundLight,
                    color: viewType === "upcoming" ? white : textSecondary,
                  }}
                >
                  {t("publicCourses.coursesUpcoming")}
                </Button>
                <Button
                  bg={viewType === "past" ? primary : backgroundLight}
                  color={viewType === "past" ? white : textSecondary}
                  onClick={() => handleViewSwitch("past")}
                  fontSize="sm"
                  p="0.5rem 1rem"
                  borderRadius="1rem"
                  _hover={{
                    bg: viewType === "upcoming" ? white : primary,
                    color: viewType === "upcoming" ? textSecondary : white,
                  }}
                >
                  {t("publicCourses.coursesPast")}
                </Button>
              </Flex>
            </Flex>
          )}
        </Flex>

        <Flex direction="column" w="100%" bg={backgroundLight}>
          <Flex
            w="100%"
            maxW={["100%", "100%", "1200px"]}
            m="0 auto"
            p={["3rem 0 3rem", "3rem 0 3rem", "3rem 0 6rem"]}
            direction="column"
          >
            {/* Results Count */}
            {searchQuery && hasResults && !loading && !isSearching && (
              <Text
                fontSize="sm"
                color={textPrimary}
                fontWeight="600"
                textAlign="center"
                m="0 0 1rem"
              >
                {t("publicCourses.resultsWithCount")}: ({resultsCount} )
              </Text>
            )}
            {!loading && !isSearching ? (
              hasResults ? (
                <CoursesPublicList
                  courses={currentCourses}
                  language={i18n.language}
                  openCheckout={openCheckout}
                />
              ) : (
                <Flex
                  w="100%"
                  h="100%"
                  align="center"
                  justify="flex-start"
                  direction="column"
                  p="0rem auto 0"
                  minH={["80vh", "80vh", "80vh"]}
                >
                  <Image h="4rem" w="auto" m="3rem 0 0" src={noServices} />
                  <Heading
                    fontSize="1.125rem"
                    color={textPrimary}
                    fontWeight="600"
                    textAlign="center"
                    m="1rem 0 0.5rem"
                  >
                    {t("publicCourses.noResults")}
                  </Heading>
                  <Text
                    fontSize="sm"
                    color={textSecondary}
                    fontWeight="500"
                    textAlign="center"
                  >
                    {t("publicCourses.noResultsDescription")}
                  </Text>
                </Flex>
              )
            ) : (
              <Flex
                w="100%"
                h="100%"
                align="center"
                justify="flex-start"
                direction="column"
                m="0rem auto 0"
                p="3rem 0 0"
                minH="60vh"
              >
                <Spinner size="xl" color={primary} />
              </Flex>
            )}
          </Flex>
          <Flex
            bg={white}
            borderTop={`1px solid ${newBorder}`}
            w="100%"
            p="3rem 0"
            borderBottom={`1px solid ${newBorder}`}
          >
            <Flex w="100%" maxW="1200px" m="0 auto">
              <Faq />
            </Flex>
          </Flex>
        </Flex>
        <Footer />
      </Flex>
    </>
  );
};

export default CoursesPublic;
