import React from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Textarea,
  FormErrorMessage,
  Tooltip,
} from "@chakra-ui/react";
import {
  white,
  newBorder,
  textPrimary,
  errorRed,
  primary,
} from "../../../../../utils/colors";

const FormControlTextArea = ({
  isRequired,
  label,
  value,
  placeholder,
  onChange,
  isTouched,
  isError,
  w,
  errorMessage,
  isSmall,
  isDisabled,
  onFocus,
  onBlur,
  noDisabledOpacity,
  isTooltip,
  tooltipLabel,
  mb,
  h, // Height for Textarea
}) => {
  return (
    <FormControl
      mb={mb ? mb : [0, 0, 4]}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
      isDisabled={isDisabled}
      position="relative"
    >
      <FormLabel
        fontSize={isSmall ? "xs" : "sm"}
        position="relative"
        color={textPrimary}
        _disabled={{
          opacity: noDisabledOpacity ? 1 : 0.5,
        }}
      >
        {label}
        {isError && isTouched && (
          <Box position="absolute" top={-1} right={0} zIndex={2}>
            <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
          </Box>
        )}
      </FormLabel>
      <Tooltip
        label={tooltipLabel}
        placement="top"
        p="0.5rem 1rem"
        borderRadius="1rem"
        isDisabled={!isTooltip}
      >
        <Textarea
          bg={white}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          size={isSmall ? "md" : "lg"}
          fontSize={isSmall ? "sm" : "0.938rem"}
          fontWeight="500"
          color={textPrimary}
          border={`1px solid ${newBorder}`}
          borderRadius="0.75rem"
          boxShadow="none !important"
          h={h ? h : "100px"} // Default height if not provided
          _focus={{
            border:
              isError && isTouched
                ? `1px solid ${errorRed}`
                : `1px solid ${primary}`,
            boxShadow: "none",
          }}
          _disabled={{
            opacity: noDisabledOpacity ? 0.5 : 0.5,
            cursor: "not-allowed",
          }}
        />
      </Tooltip>
    </FormControl>
  );
};

export default FormControlTextArea;
