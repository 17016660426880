import React, { useState, useEffect } from "react";
import { Flex, Text } from "@chakra-ui/react";
import TooltipText from "../../../../../components/partials/TooltipText";
import { useTranslation } from "react-i18next";

const ReportPatientInformation = ({ data }) => {
  const { t, i18n } = useTranslation();
  const [translatedData, setTranslatedData] = useState({});

  // Utility function to return "-" if value is undefined or null
  const safeValue = (value, translationKey) => {
    if (value === undefined || value === null || value === "") return "-";
    return translationKey ? t(`${translationKey}.${value}`) || "-" : value;
  };

  // Recompute translations when language changes
  useEffect(() => {
    setTranslatedData({
      patientInfoArray: [
        { label: t("form.patientCode"), value: safeValue(data?.patientCode) },
        { label: t("form.patientAge"), value: safeValue(data?.patientAge) },
        {
          label: t("form.patientGender"),
          value: safeValue(data?.patientGender, "gender"),
        },
      ],
      patientInfoArray2: [
        {
          label: t("form.patientHygiene"),
          value: safeValue(data?.patientHygiene, "patientHygiene"),
        },
        {
          label: t("form.patientRisks"),
          value: safeValue(data?.patientRisks, "patientRisks"),
        },
      ],
    });
  }, [i18n.language, data]); // Runs when language or data changes

  return (
    <Flex w="100%" direction="column">
      <Flex
        w="100%"
        justify="space-between"
        p={["0 0rem 0rem", "0 2rem 0rem"]}
        gap="2rem"
        direction={["column", "row"]}
      >
        <Flex w={["100%", "50%"]} direction="column">
          {translatedData.patientInfoArray?.map((info, index) => (
            <Flex key={index} align="center" mb="0.5rem">
              <TooltipText
                icon="TbInfoCircle"
                label={info.label}
                value={info.value || "-"}
                iconText
                noTooltip
              />
            </Flex>
          ))}
        </Flex>

        <Flex w={["100%", "50%"]} direction="column">
          {translatedData.patientInfoArray2?.map((info, index) => (
            <Flex key={index} align="center" mb="0.5rem">
              <TooltipText
                icon="TbInfoCircle"
                label={info.label}
                value={info.value || "-"}
                iconText
                noTooltip
              />
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ReportPatientInformation;
