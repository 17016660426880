import React from "react";
import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";
import Select from "react-select";
import {
  newBorder,
  white,
  primary,
  textPrimary,
  textSecondary,
} from "../../../../../utils/colors";
import { useTranslation } from "react-i18next";

const FormControlPatientHygiene = ({
  label,
  value,
  placeholder,
  onChange,
  isRequired,
  isTouched,
  isError,
  errorMessage,
  w,
  isSmall,
  isDisabled,
}) => {
  const { t } = useTranslation();

  // Define options inline (removing useState and useEffect)
  const options = [
    { value: "excellent", label: t("patientHygiene.excellent") },
    { value: "good", label: t("patientHygiene.good") },
    { value: "adequate", label: t("patientHygiene.adequate") },
    { value: "poor", label: t("patientHygiene.poor") },
  ];

  // Ensure no preselection (default to `null`)
  const selectedOption =
    options.find((option) => option.value === value) || null;

  return (
    <FormControl
      mb={4}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
      isDisabled={isDisabled}
    >
      <FormLabel fontSize={isSmall ? "xs" : "sm"} position="relative" w="100%">
        {label}
        {isError && isTouched && (
          <div>
            <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
          </div>
        )}
      </FormLabel>

      <Select
        isSearchable
        value={selectedOption}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: isError
              ? "red"
              : state.isFocused
              ? newBorder
              : newBorder,
            borderRadius: "1rem",
            backgroundColor: white,
            border: `1px solid ${newBorder}`,
            fontSize: "0.938rem",
            fontWeight: "500",
            minHeight: "2.5rem",
            height: "3rem",
            padding: "0 0.25rem",
            pointerEvents: isDisabled ? "none" : "auto",
            opacity: isDisabled ? 0.5 : 1,
            width: "100%",
          }),
          singleValue: (provided) => ({
            ...provided,
            color: textPrimary,
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? primary : white,
            color: state.isFocused ? white : textSecondary,
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }),
          menu: (provided) => ({
            ...provided,
            borderRadius: "1rem",
            border: `0px solid ${newBorder}`,
            bg: white,
            overflow: "hidden",
            padding: 0,
            margin: "0.5rem 0 0",
            zIndex: 10,
            width: "100%",
          }),
        }}
        isDisabled={isDisabled}
      />
    </FormControl>
  );
};

export default FormControlPatientHygiene;
