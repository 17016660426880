import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  productListReducer,
  productDetailsReducer,
  productDeleteReducer,
  productCreateReducer,
  productUpdateReducer,
  productReviewCreateReducer,
  productTopRatedReducer,
  productSupplierAddReducer,
  productSuppliersListReducer,
  productSupplierUpdateReducer,
  productSupplierDeleteReducer,
  productListByCategoryReducer,
  productAddCategoryReducer,
} from "./reducers/productReducers";
import { cartReducer } from "./reducers/cartReducers";
import {
  userLoginReducer,
  userRegisterReducer,
  userNewEmployeeRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userDeleteReducer,
  userUpdateReducer,
  userListReducer,
  userStatusUpdateReducer,
  userRegisterVerificationReducer,
  userVerifyEmailReducer,
  userRegisteredCoursesPublicReducer,
  userClientGetReportsReducer,
  usersGetClientsReducer,
  userGetClientDetailsReducer,
  userGetSingleReportReducer,
  userGetSingleEventReducer,
  userUpdateDetailsReducer,
  userCancelReportReducer,
  userGetClientsSimpleReducer,
  userClientUpdateEmployeeReducer,
  userClientGetEmployeesReducer,
  userClientGetEmployeesSimpleReducer,
  userClientEmployeeAttendeeCourseRegisterReducer,
  userVerifyEmailTokenReducer,
  userRegisteredCoursesPublicUpdateStatusReducer,
  userForgotPasswordReducer,
  userResetPasswordReducer,
  userAuthReducer,
} from "./reducers/userReducers";
import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
  orderDeliverReducer,
  orderListMyReducer,
  orderListReducer,
} from "./reducers/orderReducers";

import {
  supplierListReducer,
  supplierCreateReducer,
  supplierDeleteReducer,
  supplierDetailsReducer,
  supplierUpdateReducer,
} from "./reducers/supplierReducers";

import {
  categoryListReducer,
  categoryDetailsReducer,
  categoryCreateReducer,
  categoryUpdateReducer,
  categoryDeleteReducer,
} from "./reducers/categoryReducers";

import {
  courseListReducer,
  courseDetailsReducer,
  courseUpdateReducer,
  courseCreateReducer,
  courseDeleteReducer,
  courseAttendeesListReducer,
  courseAttendeeAddReducer,
  courseAttendeeRemoveReducer,
  courseSpeakersListReducer,
  courseSpeakerAddReducer,
  courseSpeakerRemoveReducer,
  courseStatusUpdateReducer,
  courseAttendeeStatusUpdateReducer,
  courseDescriptionUpdateReducer,
  courseTasksListReducer,
  courseAttendeePaidStatusUpdateReducer,
  courseTaskAddReducer,
  courseTaskUpdateReducer,
  courseTasksPositionsUpdateReducer,
  courseUpdatePricingReducer,
  courseUpdateImageReducer,
  courseTaskRemoveReducer,
  courseTaskSubtaskAddReducer,
  courseTaskSubtaskRemoveReducer,
  courseAttendeePriceUpdateReducer,
  coursePublicListReducer,
  coursePublicDetailsReducer,
  courseAddAttendeePublicReducer,
  courseVerifyAttendeeEmailReducer,
  courseVerifyAttendeeEmailTokenReducer,
  courseDuplicateReducer,
} from "./reducers/courseReducers";

import {
  speakerListReducer,
  speakerDetailsReducer,
  speakerCreateReducer,
  speakerUpdateReducer,
  speakerDeleteReducer,
  speakerAddCourseReducer,
  speakerRemoveCourseReducer,
  speakerStatusUpdateReducer,
  speakerDescriptionUpdateReducer,
  speakerCoursesListReducer,
  speakerImageUpdateReducer,
} from "./reducers/speakerReducers";

import {
  attendeeListReducer,
  attendeeListSimpleReducer,
  attendeeDetailsReducer,
  attendeeCreateReducer,
  attendeeUpdateReducer,
  attendeeDeleteReducer,
  attendeeAddCourseReducer,
  attendeeRemoveCourseReducer,
  attendeeStatusUpdateReducer,
  attendeeCoursesListReducer,
  attendeeCourseStatusUpdateReducer,
  attendeeCoursePaidStatusUpdateReducer,
  attendeeUpdateImageReducer,
} from "./reducers/attendeeReducers";

import {
  clinicListReducer,
  clinicDetailsReducer,
  clinicCreateReducer,
  clinicUpdateReducer,
  clinicDeleteReducer,
} from "./reducers/clinicReducers";

import {
  clientListReducer,
  clientDetailsReducer,
  clientCreateReducer,
  clientUpdateReducer,
  clientDeleteReducer,
} from "./reducers/clientReducers";

import {
  professionListReducer,
  professionDetailsReducer,
  professionCreateReducer,
  professionUpdateReducer,
  professionDeleteReducer,
  professionUpdateStatusReducer,
} from "./reducers/professionReducers";

import {
  professionGroupListReducer,
  professionGroupDetailsReducer,
  professionGroupCreateReducer,
  professionGroupUpdateReducer,
  professionGroupDeleteReducer,
  professionGroupUpdateStatusReducer,
  professionGroupUpdateIsConsultingReducer,
} from "./reducers/professionGroupReducers";

import {
  reportImplantFailureListReducer,
  reportImplantFailureDetailsReducer,
  reportImplantFailureCreateReducer,
  reportImplantFailureUpdateReducer,
  reportImplantFailureDeleteReducer,
  reportImplantFailureUpdateStatusReducer,
} from "./reducers/reportReducers";

import {
  dashboardNewClientsReducer,
  dashboardNewClientsTopReducer,
  dashboardReportStatusCountsReducer,
  dashboardCoursesAttendeesStatusCountsReducer,
  dashboardClientsStatusCountsReducer,
  dashboardTopReportsByFixtureReducer,
  dashboardEmployeeClientsLatestReportsReducer,
} from "./reducers/dashboardReducers";

import {
  createRecordReducer,
  getRecordsReducer,
  getRecordDetailsReducer,
  deleteRecordReducer,
  getPublicRecordMinimalDetailsReducer,
  getPublicRecordFullDetailsReducer,
  generateReportFilesReducer,
  sendRecordReducer,
  getRecordReportsReducer,
  downloadRecordFileReducer,
  getRecordCustomIdReducer,
  downloadFileReducer,
} from "./reducers/recordReducers";

const reducer = combineReducers({
  courseList: courseListReducer,
  courseDetails: courseDetailsReducer,
  courseUpdate: courseUpdateReducer,
  courseCreate: courseCreateReducer,
  courseDelete: courseDeleteReducer,
  courseAttendeesList: courseAttendeesListReducer,
  courseAttendeeAdd: courseAttendeeAddReducer,
  courseAttendeeRemove: courseAttendeeRemoveReducer,
  courseSpeakersList: courseSpeakersListReducer,
  courseSpeakerAdd: courseSpeakerAddReducer,
  courseSpeakerRemove: courseSpeakerRemoveReducer,
  courseStatusUpdate: courseStatusUpdateReducer,
  courseAttendeeStatusUpdate: courseAttendeeStatusUpdateReducer,
  courseDescriptionUpdate: courseDescriptionUpdateReducer,
  courseTasksList: courseTasksListReducer,
  courseAttendeePaidStatusUpdate: courseAttendeePaidStatusUpdateReducer,
  courseTaskAdd: courseTaskAddReducer,
  courseTaskUpdate: courseTaskUpdateReducer,
  courseTasksPositionsUpdate: courseTasksPositionsUpdateReducer,
  courseUpdatePricing: courseUpdatePricingReducer,
  courseUpdateImage: courseUpdateImageReducer,
  courseTaskRemove: courseTaskRemoveReducer,
  courseTaskSubtaskAdd: courseTaskSubtaskAddReducer,
  courseTaskSubtaskRemove: courseTaskSubtaskRemoveReducer,
  courseAttendeePriceUpdate: courseAttendeePriceUpdateReducer,
  coursePublicList: coursePublicListReducer,
  coursePublicDetails: coursePublicDetailsReducer,
  courseAddAttendeePublic: courseAddAttendeePublicReducer,
  courseVerifyAttendeeEmail: courseVerifyAttendeeEmailReducer,
  courseVerifyAttendeeEmailToken: courseVerifyAttendeeEmailTokenReducer,
  courseDuplicate: courseDuplicateReducer,

  productList: productListReducer,
  productDetails: productDetailsReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  productReviewCreate: productReviewCreateReducer,
  productTopRated: productTopRatedReducer,
  productSupplierAdd: productSupplierAddReducer,
  productSuppliersList: productSuppliersListReducer,
  productSupplierUpdate: productSupplierUpdateReducer,
  productSupplierDelete: productSupplierDeleteReducer,
  productListByCategory: productListByCategoryReducer,

  productAddCategory: productAddCategoryReducer,

  cart: cartReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userNewEmployeeRegister: userNewEmployeeRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  userStatusUpdate: userStatusUpdateReducer,
  userRegisterVerification: userRegisterVerificationReducer,
  userVerifyEmail: userVerifyEmailReducer,
  userRegisteredCoursesPublic: userRegisteredCoursesPublicReducer,
  userClientGetReports: userClientGetReportsReducer,
  usersGetClients: usersGetClientsReducer,
  userGetClientDetails: userGetClientDetailsReducer,
  userGetSingleReport: userGetSingleReportReducer,
  userGetSingleEvent: userGetSingleEventReducer,
  userUpdateDetails: userUpdateDetailsReducer,
  userCancelReport: userCancelReportReducer,
  userGetClientsSimple: userGetClientsSimpleReducer,
  userClientUpdateEmployee: userClientUpdateEmployeeReducer, //manager update
  userClientGetEmployees: userClientGetEmployeesReducer,
  userClientGetEmployeesSimple: userClientGetEmployeesSimpleReducer,
  userClientEmployeeAttendeeCourseRegister:
    userClientEmployeeAttendeeCourseRegisterReducer,
  userVerifyEmailToken: userVerifyEmailTokenReducer,
  userRegisteredCoursesPublicUpdateStatus:
    userRegisteredCoursesPublicUpdateStatusReducer,
  userForgotPassword: userForgotPasswordReducer,
  userResetPassword: userResetPasswordReducer,
  userAuthLogin: userAuthReducer,

  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  orderDeliver: orderDeliverReducer,
  orderListMy: orderListMyReducer,
  orderList: orderListReducer,

  supplierList: supplierListReducer,
  supplierDetails: supplierDetailsReducer,
  supplierCreate: supplierCreateReducer,
  supplierUpdate: supplierUpdateReducer,
  supplierDelete: supplierDeleteReducer,

  categoryList: categoryListReducer,
  categoryDetails: categoryDetailsReducer,
  categoryCreate: categoryCreateReducer,
  categoryUpdate: categoryUpdateReducer,
  categoryDelete: categoryDeleteReducer,

  speakerList: speakerListReducer,
  speakerDetails: speakerDetailsReducer,
  speakerCreate: speakerCreateReducer,
  speakerUpdate: speakerUpdateReducer,
  speakerDelete: speakerDeleteReducer,
  speakerAddCourse: speakerAddCourseReducer,
  speakerRemoveCourse: speakerRemoveCourseReducer,
  speakerStatusUpdate: speakerStatusUpdateReducer,
  speakerDescriptionUpdate: speakerDescriptionUpdateReducer,
  speakerCoursesList: speakerCoursesListReducer,
  speakerUpdateImage: speakerImageUpdateReducer,

  attendeeList: attendeeListReducer,
  attendeeListSimple: attendeeListSimpleReducer,
  attendeeDetails: attendeeDetailsReducer,
  attendeeCreate: attendeeCreateReducer,
  attendeeUpdate: attendeeUpdateReducer,
  attendeeDelete: attendeeDeleteReducer,
  attendeeAddCourse: attendeeAddCourseReducer,
  attendeeRemoveCourse: attendeeRemoveCourseReducer,
  attendeeStatusUpdate: attendeeStatusUpdateReducer,
  attendeeCoursesList: attendeeCoursesListReducer,
  attendeeCourseStatusUpdate: attendeeCourseStatusUpdateReducer,
  attendeeCoursePaidStatusUpdate: attendeeCoursePaidStatusUpdateReducer,
  attendeeUpdateImage: attendeeUpdateImageReducer,

  clinicList: clinicListReducer,
  clinicDetails: clinicDetailsReducer,
  clinicCreate: clinicCreateReducer,
  clinicUpdate: clinicUpdateReducer,
  clinicDelete: clinicDeleteReducer,

  clientList: clientListReducer,
  clientDetails: clientDetailsReducer,
  clientCreate: clientCreateReducer,
  clientUpdate: clientUpdateReducer,
  clientDelete: clientDeleteReducer,

  professionList: professionListReducer,
  professionDetails: professionDetailsReducer,
  professionCreate: professionCreateReducer,
  professionUpdate: professionUpdateReducer,
  professionDelete: professionDeleteReducer,
  professionUpdateStatus: professionUpdateStatusReducer,

  professionGroupList: professionGroupListReducer,
  professionGroupDetails: professionGroupDetailsReducer,
  professionGroupCreate: professionGroupCreateReducer,
  professionGroupUpdate: professionGroupUpdateReducer,
  professionGroupDelete: professionGroupDeleteReducer,
  professionGroupUpdateStatus: professionGroupUpdateStatusReducer,
  professionGroupUpdateIsConsulting: professionGroupUpdateIsConsultingReducer,

  reportImplantFailureList: reportImplantFailureListReducer,
  reportImplantFailureDetails: reportImplantFailureDetailsReducer,
  reportImplantFailureCreate: reportImplantFailureCreateReducer,
  reportImplantFailureUpdate: reportImplantFailureUpdateReducer,
  reportImplantFailureDelete: reportImplantFailureDeleteReducer,
  reportImplantFailureUpdateStatus: reportImplantFailureUpdateStatusReducer,

  dashboardNewClients: dashboardNewClientsReducer,
  dashboardNewClientsTop: dashboardNewClientsTopReducer,
  dashboardReportStatusCounts: dashboardReportStatusCountsReducer,
  dashboardCoursesAttendeesStatusCounts:
    dashboardCoursesAttendeesStatusCountsReducer,
  dashboardClientsStatusCounts: dashboardClientsStatusCountsReducer,
  dashboardTopReportsByFixture: dashboardTopReportsByFixtureReducer,
  dashboardEmployeeClientsLatestReports:
    dashboardEmployeeClientsLatestReportsReducer,

  createRecord: createRecordReducer,
  getRecords: getRecordsReducer,
  getRecordDetails: getRecordDetailsReducer,
  deleteRecord: deleteRecordReducer,
  getPublicRecordMinimalDetails: getPublicRecordMinimalDetailsReducer,
  getPublicRecordFullDetails: getPublicRecordFullDetailsReducer,
  generateReportFiles: generateReportFilesReducer,
  sendRecord: sendRecordReducer,
  getRecordReports: getRecordReportsReducer,
  downloadRecordFile: downloadRecordFileReducer,
  getRecordCustomId: getRecordCustomIdReducer,
  downloadFile: downloadFileReducer,
});

const cartItemsFromStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const shippingAddressFromStorage = localStorage.getItem("shippingAddress")
  ? JSON.parse(localStorage.getItem("shippingAddress"))
  : {};

const paymentMethodFromStorage = localStorage.getItem("paymentMethod")
  ? JSON.parse(localStorage.getItem("paymentMethod"))
  : {};

/* const productFromStorage = localStorage.getItem("product")
  ? JSON.parse(localStorage.getItem("product"))
  : {};
 */
const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    shippingAddress: shippingAddressFromStorage,
    paymentMethod: paymentMethodFromStorage,
  },
  userLogin: { userInfo: userInfoFromStorage },
  orderPay: { success: false },

  /* productDetails: { product: productFromStorage }, */
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
