import React, { useState, useEffect } from "react";
import { Flex, Box, Heading, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import TooltipText from "../../../../../components/partials/TooltipText";
import { textPrimary, textSecondary } from "../../../../../utils/colors";

const ReportCreatedInfo = ({ data, isClient }) => {
  const { t } = useTranslation();

  // Determine author display name
  let authorName = "";

  if (data?.authorType === "client") {
    if (data?.clientType === "individual") {
      authorName = `${data?.author.firstName} ${data?.author.lastName}`.trim();
    } else {
      authorName = data?.author.companyName;
    }
  } else if (data?.authorType === "employee") {
    authorName = `${data?.author.firstName} ${data?.author.lastName}`.trim();
  }

  let authorType = "";

  if (data?.authorType === "client") {
    authorType = t("form.client");
  } else {
    authorType = t("form.admin");
  }

  // console.log("data", data);

  return (
    <Flex w="100%" direction="column">
      <Heading
        fontSize="md"
        fontWeight="600"
        color={textPrimary}
        m="1.5rem 0 1rem"
      >
        {t("implantFailureReports.createdInfoTitle")}
      </Heading>
      <Flex direction="column" gap="0.35rem">
        {isClient ? (
          <TooltipText
            icon="TbMapPin"
            label={t("form.submittedBy")}
            value={`${authorType}` || "-"}
            iconText
            maxW="240px"
          />
        ) : (
          <TooltipText
            icon="TbMapPin"
            label={t("form.submittedBy")}
            value={`${authorType} (${authorName})` || "-"}
            iconText
            maxW="240px"
          />
        )}

        {/* <TooltipText
          icon="TbMapPin"
          label={
            data?.authorType === "client" ? t("form.client") : t("form.admin")
          }
          value={authorName || "-"}
          iconText
          noTooltip={authorName ? false : true}
          maxW="260px"
        /> */}

        <TooltipText
          icon="TbMapPin"
          label={t("implantFailureReports.reportNotes")}
          value={data?.notes || "-"}
          iconText
          noTooltip={authorName ? false : true}
          maxW="220px"
        />
      </Flex>
    </Flex>
  );
};

export default ReportCreatedInfo;
