import axios from "axios";
import React, { useState } from "react";
import {
  Button,
  Image,
  Spinner,
  Stack,
  useToast,
  Text,
  Flex,
  HStack,
  Box,
  Icon,
} from "@chakra-ui/react";
import { icons } from "../../../../../utils/icons";
import styled from "@emotion/styled";
import {
  backgroundLight,
  newBorder,
  textSecondary,
  textPrimary,
  primary,
  white,
} from "../../../../../utils/colors";
import { useTranslation } from "react-i18next";
import { TbRadioactiveFilled } from "react-icons/tb";

const ImageUpload = ({ id, currentImage, image, setImage, label }) => {
  const [uploading, setUploading] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const toast = useToast();
  const { t } = useTranslation();

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post("/api/upload", formData, config);

      setImage(data);
      setUploading(false);

      // Display success toast
      toast({
        title: t("imageUpload.successTitle"),
        // description: t("imageUpload.successDescription"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
      setUploading(false);

      // Display error toast
      toast({
        title: t("imageUpload.errorTitle"),
        // description: t("imageUpload.errorDescription"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleRemoveImage = () => {
    setImage("");
    toast({
      title: t("toasts.imageRemoveSuccess"),
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Flex
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      border={`1px solid ${newBorder}`}
      borderRadius="0.75rem"
      p="1rem"
      direction="column"
    >
      {!uploading ? (
        <Flex w="100%" align="center" justify="center">
          {image && (
            <Box
              w="100%"
              minH="140px"
              maxH="140px"
              position="relative"
              borderRadius="1rem"
              overflow="hidden"
            >
              <Image
                src={currentImage || image || icons.noImage}
                objectFit="cover"
                h="100%"
                w="100%"
              />
            </Box>
          )}
        </Flex>
      ) : (
        <Spinner color={primary} />
      )}

      <Stack spacing={0} w="100%">
        <Flex
          align="center"
          justify="center"
          direction="column"
          gap={0}
          w="100%"
          my={3}
          p="0 1.5rem"
          textAlign="center"
        >
          <Text color={textPrimary} fontSize="sm" fontWeight="500">
            {image ? (
              t("imageUpload.changeImage")
            ) : label ? (
              <Flex align="center" justify="center" direction="column">
                <Icon
                  as={TbRadioactiveFilled}
                  fontSize="1rem"
                  color={textSecondary}
                  mb="0.5rem"
                />
                <Text fontSize="sm" fontWeight="500" color={textSecondary}>
                  {label}
                </Text>
              </Flex>
            ) : (
              t("imageUpload.title")
            )}
          </Text>
          {/* <Text color={textSecondary} fontSize="xs" fontWeight="400">
            {image ? image : t("imageUpload.description")}
          </Text> */}
        </Flex>
      </Stack>

      <HStack>
        {!image ? (
          <UploadButton htmlFor={id} className="custom-file-upload">
            <Icon2 src={icons.upload} />

            {image ? (
              <Flex>{t("imageUpload.change")}</Flex>
            ) : (
              <Flex>{t("imageUpload.browse")}</Flex>
            )}
          </UploadButton>
        ) : null}
        {image && (
          <Button
            fontSize="13px"
            p="10px 1.5rem"
            onClick={handleRemoveImage}
            borderRadius="12px"
            bg={backgroundLight}
            color={textSecondary}
            _hover={{ bg: "#ddd", color: "#000" }}
          >
            {t("imageUpload.remove")}
          </Button>
        )}
        <input
          id={id}
          type="file"
          onChange={uploadFileHandler}
          style={{ display: "none" }}
        />
      </HStack>
    </Flex>
  );
};
const Icon2 = styled.img`
  height: 12px;
  margin-right: 8px;
`;

const UploadButton = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  padding: 10px 1.5rem;
  margin: 0 0.5rem 0 0;
  cursor: pointer;
  background: ${backgroundLight};
  color: ${textSecondary};
  font-size: 13px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: ${primary};
    color: ${white};
  }
`;

export default ImageUpload;
