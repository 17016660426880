import React, { useState, useEffect } from "react";
import {
  HStack,
  Button,
  Flex,
  Tooltip,
  FormLabel,
  Icon,
  Text,
} from "@chakra-ui/react";
import {
  white,
  textPrimary,
  newBorder,
  primary,
  buttonRed,
  buttonRedText,
  textSecondary,
} from "../../../../../utils/colors";
import FormControlFixtureExchange from "../components/FormControlFixtureExchange";
import FormControlDiameter from "../components/FormControlDiameter";
import FormControlLength from "../components/FormControlLength";
import FormControlCore from "../components/FormControlCore";
import { useTranslation } from "react-i18next";
import { FaExchangeAlt } from "react-icons/fa";
import { shade } from "polished";
import FormControlTextArea from "../components/FormControlTextArea";

const Step4 = ({
  handleNewFixtureChange,
  fixtureOptions,
  newFixture,
  setNewFixture,
  newCore,
  setNewCore,
  newDiameter,
  setNewDiameter,
  newLength,
  setNewLength,
  setStepSuccess,
  fixture,
  core,
  diameter,
  length,
  newRefCode,
  setNewRefCode,
  lotNumber,
  handleSubmit,
  isValid,
  isStep0Valid,
  isStep1Valid,
  isStep2Valid,
  isStep3Valid,
  isStep4Valid,
  notes,
  setNotes,
}) => {
  // GENERAL
  const { t } = useTranslation();

  //VALIDATION

  const isCoreValid = newCore !== null && newCore !== "";
  const isDiameterValid = newDiameter !== "" && newDiameter !== null;
  const isLengthValid = newLength !== "" && newLength !== null;

  const isContinueDisabled = !isDiameterValid || !isLengthValid;

  // NEW REF CODE STATE
  const [prefix, setPrefix] = useState("");
  const [codeDiameter, setCodeDiameter] = useState("");
  const [codeLength, setCodeLength] = useState("");
  const [codeCore, setCodeCore] = useState("");

  // CHECK
  const isCoreNeeded = newFixture
    ? fixtureOptions.find((f) => f.value === newFixture).core
    : false;

  // VALIDATION
  const allStepsValid =
    isStep0Valid &&
    isStep1Valid &&
    isStep2Valid &&
    isStep3Valid &&
    isStep4Valid;

  // USE EFFECT
  useEffect(() => {
    if (newFixture === "mini") {
      setPrefix("MIF");
    } else if (newFixture === "st") {
      setPrefix("ST");
    } else if (newFixture === "anyone") {
      setPrefix("IF");
    } else if (newFixture === "anyridge") {
      setPrefix("AR");
    } else if (newFixture === "bluediamond") {
      setPrefix("ARO");
    } else if (newFixture === "ezplus") {
      setPrefix("EZIR");
    } else if (newFixture === "rescue") {
      setPrefix("RSWIR");
    } else {
      setPrefix(""); // Clear code if fixture doesn't match any condition
    }
  }, [newFixture, core, diameter]);

  useEffect(() => {
    if (newFixture === "anyridge" && (newCore === "2.8" || newCore === "3.3")) {
      setPrefix("FANIHX");
    } else if (newFixture === "anyridge" && newCore === "3.8") {
      setPrefix("AR38-");
    } else if (
      newFixture === "anyridge" &&
      newCore === "4.0" &&
      (newDiameter === "5.0" || newDiameter === "5.5")
    ) {
      setPrefix("FANIHX");
    } else if (
      newFixture === "anyridge" &&
      newCore === "4.0" &&
      newDiameter === "6.0"
    ) {
      setPrefix("AR40");
    } else if (newFixture === "anyridge" && newCore === "4.3") {
      setPrefix("AR43");
    } else if (
      newFixture === "anyridge" &&
      newCore === "4.8" &&
      (newDiameter === "6.0" ||
        newDiameter === "6.5" ||
        newDiameter === "7.0" ||
        newDiameter === "7.5" ||
        newDiameter === "8.0")
    ) {
      setPrefix("FANIHX");
    } else if (
      newFixture === "anyridge" &&
      newCore === "4.8" &&
      newFixture === "5.5"
    ) {
      setPrefix("AR48");
    }
  }, [newFixture, newCore, newDiameter]);

  useEffect(() => {
    setCodeDiameter("");

    if (newDiameter === "3.0") {
      setCodeDiameter("30");
    } else if (newDiameter === "3.3") {
      setCodeDiameter("33");
    } else if (newDiameter === "3.5") {
      setCodeDiameter("35");
    } else if (newDiameter === "3.7") {
      setCodeDiameter("37");
    } else if (newDiameter === "4.0") {
      setCodeDiameter("40");
    } else if (newDiameter === "4.1") {
      setCodeDiameter("41");
    } else if (newDiameter === "4.4") {
      setCodeDiameter("44");
    } else if (newDiameter === "4.5") {
      setCodeDiameter("45");
    } else if (newDiameter === "4.8") {
      setCodeDiameter("48");
    } else if (newDiameter === "5.0") {
      setCodeDiameter("50");
    } else if (newDiameter === "5.5") {
      setCodeDiameter("55");
    } else if (newDiameter === "6.0") {
      setCodeDiameter("60");
    } else if (newDiameter === "6.5") {
      setCodeDiameter("65");
    } else if (newDiameter === "7.0") {
      setCodeDiameter("70");
    } else if (newDiameter === "7.5") {
      setCodeDiameter("75");
    } else if (newDiameter === "8.0") {
      setCodeDiameter("80");
    } else if (newDiameter === "8.5") {
      setCodeDiameter("85");
    } else if (newDiameter === "9.0") {
      setCodeDiameter("90");
    } else {
      setCodeDiameter("");
    }
  }, [newDiameter]);

  useEffect(() => {
    setCodeLength("");

    if (newLength === "5.0") {
      setCodeLength("05");
    } else if (newLength === "6.0") {
      setCodeLength("06");
    } else if (newLength === "7.0") {
      setCodeLength("07");
    } else if (newLength === "8.5") {
      setCodeLength("08");
    } else if (newLength === "10.0") {
      setCodeLength("10");
    } else if (newLength === "11.5") {
      setCodeLength("11");
    } else if (newLength === "13.0") {
      setCodeLength("13");
    } else if (newLength === "13.5") {
      setCodeLength("13");
    } else if (newLength === "15.0") {
      setCodeLength("15");
    } else if (newLength === "18.0") {
      setCodeLength("18");
    } else {
      setCodeLength("");
    }
  }, [newLength]);

  useEffect(() => {
    setCodeCore("");

    if (newFixture === "anyone" && newCore === "deep") {
      setCodeCore("DC");
    } else if (newFixture === "anyone" && newCore === "special") {
      setCodeCore("SC");
    } else if (
      newFixture === "anyridge" &&
      newCore === "4.0" &&
      (newDiameter === "5.0" || newDiameter === "5.5")
    ) {
      setCodeCore("SC");
    } else if (newFixture === "bluediamond" && newCore === "deep") {
      setCodeCore("DC");
    } else if (newFixture === "ezplus") {
      setCodeCore("N");
    } else if (newFixture) {
      setCodeCore("C");
    }
  }, [newFixture, newCore, newDiameter]);

  useEffect(() => {
    setNewRefCode(prefix + codeDiameter + codeLength + codeCore);
  }, [prefix, codeDiameter, codeLength, codeCore, setNewRefCode]);

  // LOGS
  console.log("newFixture", newFixture);
  console.log("newRefCode", newRefCode);

  // RENDER
  return (
    <Flex w="100%" maxW="600px" direction="column">
      <Flex w="100%" direction="column">
        <Flex
          w="100%"
          align="flex-start"
          justify="center"
          direction="column"
          m="0 0 0rem"
        >
          <FormLabel fontSize="sm" position="relative">
            {t("implantForm.currentImplant")}
          </FormLabel>
          <Button
            border={`1px solid ${newBorder}`}
            borderRadius="1rem"
            bg={white}
            w="100%"
            h="48px"
            align="center"
            justify="flex-start"
            p="0 1rem"
            isDisabled
            _disabled={{
              opacity: 0.5,
              cursor: "not-allowed",
            }}
            _hover={{
              bg: white,
            }}
          >
            <Flex align="center" w="100%" textAlign="left">
              <Text fontSize="sm" color={textPrimary} fontWeight="500" w="100%">
                {t(`implants.${fixture}`)} -{" "}
                <Text as="span">
                  {core === null ? null : (
                    <>
                      {core === "regular" ||
                      core === "special" ||
                      core === "deep" ? (
                        <Text
                          as="span"
                          fontSize="sm"
                          color={textPrimary}
                          fontWeight="500"
                        >
                          {" "}
                          ({t(`core.${core}`)})
                        </Text>
                      ) : (
                        <Text
                          as="span"
                          fontSize="sm"
                          color={textPrimary}
                          fontWeight="500"
                        >
                          (C{core})
                        </Text>
                      )}
                    </>
                  )}
                </Text>{" "}
                Ø{diameter}mm/L{length}{" "}
              </Text>
            </Flex>
          </Button>

          <Flex w="100%" m="1.5rem auto" align="center" justify="center">
            <Icon
              as={FaExchangeAlt}
              fontSize="0.875rem"
              color={textSecondary}
              transform={"rotate(90deg)"}
              mr={2}
            />
            <Text fontSize="sm" color={textSecondary} fontWeight="500">
              {t("implantForm.exchangingTo")}
            </Text>
          </Flex>
        </Flex>

        <Flex w="100%" align="center" justify="space-between" gap="1rem">
          <FormControlFixtureExchange
            fixture={fixture}
            isRequired
            options={fixtureOptions}
            label={t("implantForm.exchangeImplant")}
            value={newFixture}
            // onChange={(selectedOption) => {
            //   setNewFixture(selectedOption.value);
            // }}
            onChange={(selectedOption) => {
              if (selectedOption) {
                setNewFixture(selectedOption.value);
                setNewCore(null);
                setNewDiameter(null);
                setNewLength(null);
              } else {
                setNewFixture(null); // or any default value you prefer
                setNewCore(null);
                setNewDiameter(null);
                setNewLength(null);
              }
            }}
            placeholder={t("implantForm.placeholderFixture")}
            w={isCoreNeeded ? "70%" : "100%"}
          />
          {isCoreNeeded && (
            <FormControlCore
              isRequired
              fixture={newFixture}
              core={newCore}
              setValue={setNewCore}
              label={t("form.core")}
              value={newCore}
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setNewCore(selectedOption.value);
                  setNewDiameter(null);
                  setNewLength(null);
                } else {
                  setNewCore(null); // or any default value you prefer
                  setNewDiameter(null);
                  setNewLength(null);
                }
              }}
              //   onChange={(selectedOption) => {
              //     setNewCore(selectedOption.value);
              //   }}
              placeholder={t("placeholder.core")}
              w="30%"
              errorMessage={t("formError.coreRequired")}
              isDisabled={!newFixture}
            />
          )}
        </Flex>

        {/* STEP 1 START */}
        <HStack gap={4} w="100%">
          <FormControlDiameter
            isRequired
            fixture={newFixture}
            setFixture={setNewFixture}
            core={newCore}
            setValue={setNewDiameter}
            label={t("form.diameter")}
            value={newDiameter}
            onChange={(selectedOption) => {
              if (selectedOption) {
                setNewDiameter(selectedOption.value);
                setNewLength(null);
              } else {
                setNewDiameter(null); // or any default value you prefer
                setNewLength(null);
              }
            }}
            placeholder={t("placeholder.diameter")}
            w="100%"
            errorMessage={t("formError.diameterRequired")}
          />

          <FormControlLength
            isRequired
            fixture={newFixture}
            core={newCore}
            diameter={newDiameter}
            setValue={setNewLength}
            label={t("form.length")}
            value={newLength}
            onChange={(selectedOption) => {
              if (selectedOption) {
                setNewLength(selectedOption.value);
              } else {
                setNewLength(null); // or any default value you prefer
              }
            }}
            //   onChange={(selectedOption) => setNewLength(selectedOption.value)}
            placeholder={t("placeholder.length")}
            w="100%"
            errorMessage={t("formError.lengthRequired")}
            isDisabled={!isDiameterValid}
          />
        </HStack>

        <Flex w="100%">
          <FormControlTextArea
            label={t("form.notes")}
            value={notes}
            placeholder={t("placeholder.notes")}
            onChange={(e) => setNotes(e.target.value)}
            // isTouched={isTouched}
            // isError={isError}
            // errorMessage="This field is required"
            w="100%"
            h="120px"
            // isRequired
          />
        </Flex>

        {/* STEP 1 END */}
      </Flex>

      <Flex w="100%" justify="flex-end" m="1.5rem 0 0">
        <Tooltip
          label={
            <Flex direction="column" align="center">
              {!isStep0Valid && (
                <Text fontWeight="500">{t("formError.step0")}</Text>
              )}

              {!isStep1Valid && (
                <Text fontWeight="500">{t("formError.step1")}</Text>
              )}

              {!isStep2Valid && (
                <Text fontWeight="500">{t("formError.step2")}</Text>
              )}

              {!isStep3Valid && (
                <Text fontWeight="500">{t("formError.step3")}</Text>
              )}

              {!isValid && <Text fontWeight="500">{t("formError.step4")}</Text>}
            </Flex>
          }
          bg={buttonRed}
          color={buttonRedText}
          placement="top"
          p="0.5rem 1rem"
          borderRadius="1rem"
          fontSize="xs"
          isDisabled={allStepsValid}
        >
          <Button
            size="lg"
            fontSize="md"
            bg={primary}
            color={white}
            fontWeight="500"
            borderRadius="10rem"
            minW="8rem"
            onClick={handleSubmit}
            _hover={{
              bg: shade(0.3, primary),
            }}
            isDisabled={!allStepsValid}
          >
            {t("implantForm.adminSubmit")}
          </Button>
        </Tooltip>
      </Flex>
    </Flex>
  );
};

export default Step4;
