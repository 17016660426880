import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  HStack,
  Button,
  Flex,
  Icon,
  Text,
  Divider,
  Box,
  AbsoluteCenter,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import {
  white,
  buttonGreen,
  buttonGreenText,
  primary,
  buttonRedText,
  textSecondary,
  textPrimary,
} from "../../../../../utils/colors";
import FormControlDefault from "../../../../../components/input/FormControlDefault";
import { useTranslation } from "react-i18next";
import { FaUserCircle } from "react-icons/fa";
import { shade } from "polished";
import SelectClientRegisterTypeReport from "../../../../../components/select/SelectClientRegisterTypeReport";
import FormControlClientSelect from "../../../../../components/input/FormControlClientSelect";
import FormControlClientTypeRegisterReport from "../../../../../components/input/FormControlClientTypeRegisterReport";

const Step0Admin = ({
  setActiveStep,
  userInfo,
  clientType,
  setClientType,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  companyName,
  setCompanyName,
  companyCode,
  setCompanyCode,
  email,
  setEmail,
  isValid,
  isLoggedIn,
  client,
  setClient,
  address,
  setAddress,
}) => {
  // GENERAL
  const { t } = useTranslation();
  const navigate = useNavigate();

  // STATE
  const [isRegisteredClient, setIsRegisteredClient] = useState(true);
  const [clientRegisterType, setClientRegisterType] = useState("registered");

  const [representingFirstName, setRepresentingFirstName] = useState("");
  const [representingLastName, setRepresentingLastName] = useState("");
  const [representingCompany, setRepresentingCompany] = useState("myMegagen");

  // CUSTOM
  const iconElement = (
    <Flex
      p="0"
      mr="0.5rem"
      h="100%"
      position="absolute"
      bottom="2px"
      top="40px"
      right="0.5rem"
      opacity="0.6"
      pointerEvents="none"
    >
      <Flex
        w="1.5rem"
        h="1.5rem"
        justify="center"
        align="center"
        bg={buttonGreen}
        borderRadius="50%"
      >
        <Icon as={FaUserCircle} color={buttonGreenText} fontSize="0.875rem" />
      </Flex>
    </Flex>
  );

  const tooltipLabel = (
    <Flex align="center">
      {clientType === "individual" ? (
        <Text fontSize="sm" fontWeight="500">
          {t("implantForm.loggedInIndividual")}
          <Text as="span" ml="0.25rem" fontWeight="600">
            {firstName} {lastName}
          </Text>
        </Text>
      ) : (
        <Text fontSize="sm" fontWeight="500">
          {t("implantForm.loggedInCompany")}
          <Text as="span" ml="0.25rem" fontWeight="600">
            {companyName}
          </Text>
        </Text>
      )}
    </Flex>
  );

  // USE EFFECT
  useEffect(() => {
    if (userInfo) {
      setRepresentingFirstName(userInfo.firstName);
      setRepresentingLastName(userInfo.lastName);
    }
  }, [userInfo, client]);

  useEffect(() => {
    if (clientRegisterType === "unregistered") {
      setClient(null);
    }
  }, [clientRegisterType]);

  // LOGS
  // console.log("client:", client);
  // console.log("userInfo:", userInfo);
  // console.log("representingFirstName:", representingFirstName);
  // console.log("representingLastName:", representingLastName);
  // console.log("representingCompany:", representingCompany);

  // console.log("isRegisteredClient:", isRegisteredClient);
  // console.log("clientRegisterType:", clientRegisterType);

  // console.log("clientType:", clientType);

  // RENDER
  return (
    <Flex w="100%" maxW="600px" direction="column">
      <FormControl mb={4} isRequired>
        {/* <FormLabel fontSize="sm" position="relative">
          {t("record.selectClientRegisterType")}
        </FormLabel> */}

        <SelectClientRegisterTypeReport
          value={clientRegisterType} // Pass the current type from state
          onChange={(newClientRegisterType) =>
            setClientRegisterType(newClientRegisterType)
          } // Update type when changed
          // isRequired
        />
      </FormControl>

      {clientRegisterType === "registered" ? (
        <>
          <FormControl isRequired>
            <FormLabel fontSize="sm">{t("form.selectClient")}</FormLabel>
            <FormControlClientSelect
              autoFocus={client ? false : true}
              onChange={(selectedOption) => {
                setClient(selectedOption);
              }}
              placeholder={t("placeholder.selectClient")}
              client={client}
            />
          </FormControl>

          <Flex direction="column">
            <Box position="relative" padding="1.5rem 0">
              <Divider />
              <AbsoluteCenter bg="white" px="4">
                <Text color={textSecondary} fontSize="xs">
                  {client &&
                    client.accountType === "clinic" &&
                    t("landingMegagen.registerClinicDetails")}
                  {client &&
                    client.accountType === "dentist" &&
                    t("landingMegagen.registerDentistDetails")}
                  {client &&
                    client.accountType === "lab" &&
                    t("landingMegagen.registerLabDetails")}
                </Text>
              </AbsoluteCenter>
            </Box>

            {client && client.clientType === "individual" ? (
              <>
                <Flex gap={4} w="100%" direction={["column", "column", "row"]}>
                  <FormControlDefault
                    type="text"
                    isRequired
                    label={t("form.firstName")}
                    value={firstName}
                    placeholder=""
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    w="50%"
                    isDisabled
                    rightElement={userInfo ? iconElement : null}
                    isTooltip={userInfo}
                    tooltipLabel={tooltipLabel}
                    noDisabledOpacity
                  />
                  <FormControlDefault
                    type="text"
                    isRequired
                    label={t("form.lastName")}
                    value={lastName}
                    placeholder=""
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    w="50%"
                    isDisabled
                    rightElement={userInfo ? iconElement : null}
                    isTooltip={userInfo}
                    tooltipLabel={tooltipLabel}
                    noDisabledOpacity
                  />
                </Flex>

                <FormControlDefault
                  type="email"
                  label={t("form.email")}
                  isRequired
                  value={email}
                  placeholder=""
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  w="100%"
                  isDisabled
                  rightElement={userInfo ? iconElement : null}
                  isTooltip={userInfo}
                  tooltipLabel={tooltipLabel}
                  noDisabledOpacity
                />
              </>
            ) : (
              <>
                <HStack gap={4} w="100%">
                  <FormControlDefault
                    type="text"
                    isRequired
                    label={t("form.companyName")}
                    value={companyName}
                    placeholder=""
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                    }}
                    w="50%"
                    isDisabled
                  />

                  <FormControlDefault
                    type="text"
                    isRequired
                    label={t("form.companyCode")}
                    value={companyCode}
                    placeholder=""
                    onChange={(e) => {
                      setCompanyCode(e.target.value);
                    }}
                    w="50%"
                    isDisabled
                  />
                </HStack>

                <FormControlDefault
                  type="email"
                  label={t("form.email")}
                  isRequired
                  value={email}
                  placeholder=""
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  w="100%"
                  isDisabled
                />
              </>
            )}
          </Flex>
        </>
      ) : (
        <Flex direction="column">
          <FormControlClientTypeRegisterReport
            label={t("implantForm.clientType")}
            placeholder={t("placeholder.selectClientType")}
            value={clientType}
            onChange={(selectedOption) => {
              setClientType(selectedOption ? selectedOption.value : "");

              const updatedValue = selectedOption.value.includes("business")
                ? "business"
                : selectedOption.value;
              setClientType(updatedValue);
            }}
            isRequired
            errorMessage={t("formError.clientTypeRequired")}
            w="100%"
          />
          {clientType === "individual" ? (
            <>
              <Flex gap={4} w="100%" direction={["column", "column", "row"]}>
                <FormControlDefault
                  type="text"
                  isRequired
                  label={t("form.firstName")}
                  value={firstName}
                  placeholder={t("placeholder.firstName")}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  w="50%"
                  // isDisabled
                  // rightElement={userInfo ? iconElement : null}
                  // isTooltip={userInfo}
                  // tooltipLabel={tooltipLabel}
                  // noDisabledOpacity
                />
                <FormControlDefault
                  type="text"
                  isRequired
                  label={t("form.lastName")}
                  value={lastName}
                  placeholder={t("placeholder.lastName")}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  w="50%"
                  // isDisabled
                  // rightElement={userInfo ? iconElement : null}
                  // isTooltip={userInfo}
                  // tooltipLabel={tooltipLabel}
                  // noDisabledOpacity
                />
              </Flex>

              <FormControlDefault
                type="email"
                label={t("form.email")}
                isRequired
                value={email}
                placeholder={t("placeholder.email")}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                w="100%"
                // isDisabled
                // rightElement={userInfo ? iconElement : null}
                // isTooltip={userInfo}
                // tooltipLabel={tooltipLabel}
                // noDisabledOpacity
              />

              <FormControlDefault
                type="text"
                isRequired
                label={
                  clientType === "individual"
                    ? t("form.representingCompany")
                    : t("form.companyName")
                }
                value={companyName}
                placeholder={t("placeholder.companyName")}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
                w="100%"
                // isDisabled
              />
            </>
          ) : (
            <>
              <HStack gap={4} w="100%">
                <FormControlDefault
                  type="text"
                  isRequired
                  label={t("form.companyName")}
                  value={companyName}
                  placeholder={t("placeholder.companyName")}
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                  }}
                  w="50%"
                  // isDisabled
                />

                <FormControlDefault
                  type="text"
                  isRequired
                  label={t("form.companyCode")}
                  value={companyCode}
                  placeholder={t("placeholder.companyCode")}
                  onChange={(e) => {
                    setCompanyCode(e.target.value);
                  }}
                  w="50%"
                  // isDisabled
                />
              </HStack>

              <FormControlDefault
                type="email"
                label={t("form.email")}
                isRequired
                value={email}
                placeholder={t("placeholder.email")}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                w="100%"
                // isDisabled
              />

              <Flex gap={4} w="100%" direction={["column", "column", "row"]}>
                <FormControlDefault
                  type="text"
                  isRequired
                  label={
                    clientType === "individual"
                      ? t("form.firstName")
                      : t("form.repFirstName")
                  }
                  value={firstName}
                  placeholder={t("placeholder.firstName")}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  w="50%"
                  // isDisabled
                  // rightElement={userInfo ? iconElement : null}
                  // isTooltip={userInfo}
                  // tooltipLabel={tooltipLabel}
                  // noDisabledOpacity
                />
                <FormControlDefault
                  type="text"
                  isRequired
                  label={
                    clientType === "individual"
                      ? t("form.lastName")
                      : t("form.repLastName")
                  }
                  value={lastName}
                  placeholder={t("placeholder.lastName")}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  w="50%"
                  // isDisabled
                  // rightElement={userInfo ? iconElement : null}
                  // isTooltip={userInfo}
                  // tooltipLabel={tooltipLabel}
                  // noDisabledOpacity
                />
              </Flex>
            </>
          )}

          <Text
            fontSize="xs"
            fontWeight="500"
            color={textSecondary}
            m="1rem 0"
            maxW="80%"
          >
            <Text
              as="span"
              fontWeight="600"
              color={textPrimary}
              textTransform="uppercase"
              mr={1}
            >
              {t("record.disclaimerShort")}:
            </Text>
            {t("record.disclaimerShortReportText")}{" "}
            <Text as="span" color={primary}>
              {" "}
              {t("client.client")}
            </Text>
            .
          </Text>
        </Flex>
      )}

      <Flex w="100%" justify="flex-end" m="1.5rem 0 0">
        {isLoggedIn ? (
          <Button
            size="lg"
            fontSize="md"
            bg={primary}
            color={white}
            fontWeight="500"
            borderRadius="10rem"
            minW="8rem"
            onClick={() => {
              setActiveStep(1);
            }}
            _hover={{
              bg: shade(0.3, primary),
            }}
            isDisabled={!isValid}
          >
            {t("common.continue")}
          </Button>
        ) : (
          <Flex align="center" justify="flex-end">
            <Text
              fontSize="sm"
              color={buttonRedText}
              fontWeight="500"
              mr="1.5rem"
            >
              {t("implantForm.registerNotLoggedIn")}
            </Text>
            <Button
              size="lg"
              fontSize="md"
              bg={primary}
              color={white}
              fontWeight="500"
              borderRadius="10rem"
              minW="8rem"
              onClick={() => {
                navigate("/register");
              }}
              _hover={{
                bg: shade(0.3, primary),
              }}
            >
              {t("implantForm.register")}
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default Step0Admin;
