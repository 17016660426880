import React from "react";
import {
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  Icon,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { backgroundLight, textPrimary } from "../../../../../utils/colors";
import { TbDentalBroken, TbUserCircle } from "react-icons/tb";
import ReportExchangeInfo from "./ReportExchangeInfo";
import ReportSurgeryInfo from "./ReportSurgeryInfo";
import ReportPatientInformation from "./ReportPatientInformation";
import { useMediaQuery } from "react-responsive";
import ReportClientInfo from "./ReportClientInfo";
import ReportCreatedInfo from "./ReportCreatedInfo";

// ACCORDION ITEM START
const ReportInfoItem = ({ title, children, icon }) => {
  return (
    <AccordionItem border="none">
      <h2>
        <AccordionButton
          bg={backgroundLight}
          color={textPrimary}
          p="1.25rem 1.5rem"
          mb="0.5rem"
          borderRadius="1rem"
          _expanded={{
            bg: backgroundLight,
            color: textPrimary,
          }}
        >
          <Flex align="center" w="100%">
            <Icon as={icon} mr="2" />
            <Text fontSize="sm" fontWeight="500">
              {title}
            </Text>
          </Flex>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel p="1rem 0 1.5rem">{children}</AccordionPanel>
    </AccordionItem>
  );
};
// ACCORDION ITEM END

// MAIN CODE START
const ReportInfoBlocks = ({ data }) => {
  const { t } = useTranslation();

  // MOBILE
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  console.log("data", data);

  return (
    <Flex direction={["column", "column"]} w="100%">
      <Box w="100%" m={["1rem 0 1rem", "0 0 2rem"]}>
        <ReportExchangeInfo data={data} />
      </Box>

      <Accordion allowMultiple>
        {isMobile && (
          <ReportInfoItem
            title={t("implantFailureReports.clientInfoTitle")}
            icon={TbUserCircle}
          >
            <ReportClientInfo data={data} />
            <ReportCreatedInfo data={data} isClient />
          </ReportInfoItem>
        )}

        <ReportInfoItem
          title={t("implantFailureReports.procedureInfoTitle")}
          icon={TbDentalBroken}
        >
          <ReportSurgeryInfo data={data} />
        </ReportInfoItem>

        <ReportInfoItem
          title={t("implantFailureReports.patientInfoTitle")}
          icon={TbUserCircle}
        >
          <ReportPatientInformation data={data} />
        </ReportInfoItem>
      </Accordion>
    </Flex>
  );
};
// MAIN CODE END

export default ReportInfoBlocks;
