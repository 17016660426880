import axios from "axios";
import {
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_DETAILS_RESET,
  USER_LIST_FAIL,
  USER_LIST_SUCCESS,
  USER_LIST_REQUEST,
  USER_LIST_RESET,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_UPDATE_FAIL,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_REQUEST,
  USER_STATUS_UPDATE_REQUEST,
  USER_STATUS_UPDATE_SUCCESS,
  USER_STATUS_UPDATE_FAIL,
  USER_REGISTER_VERIFICATION_REQUEST,
  USER_REGISTER_VERIFICATION_SUCCESS,
  USER_REGISTER_VERIFICATION_FAIL,
  USER_VERIFY_EMAIL_REQUEST,
  USER_VERIFY_EMAIL_SUCCESS,
  USER_VERIFY_EMAIL_FAIL,
  USER_GET_REGISTERED_COURSES_PUBLIC_REQUEST,
  USER_GET_REGISTERED_COURSES_PUBLIC_SUCCESS,
  USER_GET_REGISTERED_COURSES_PUBLIC_FAIL,
  USER_CLIENT_GET_REPORTS_REQUEST,
  USER_CLIENT_GET_REPORTS_SUCCESS,
  USER_CLIENT_GET_REPORTS_FAIL,
  USER_GET_CLIENTS_REQUEST,
  USER_GET_CLIENTS_SUCCESS,
  USER_GET_CLIENTS_FAIL,
  USER_GET_CLIENT_DETAILS_REQUEST,
  USER_GET_CLIENT_DETAILS_SUCCESS,
  USER_GET_CLIENT_DETAILS_FAIL,
  USER_GET_SINGLE_REPORT_REQUEST,
  USER_GET_SINGLE_REPORT_SUCCESS,
  USER_GET_SINGLE_REPORT_FAIL,
  USER_GET_SINGLE_EVENT_REQUEST,
  USER_GET_SINGLE_EVENT_SUCCESS,
  USER_GET_SINGLE_EVENT_FAIL,
  USER_UPDATE_DETAILS_REQUEST,
  USER_UPDATE_DETAILS_SUCCESS,
  USER_UPDATE_DETAILS_FAIL,
  USER_CANCEL_REPORT_REQUEST,
  USER_CANCEL_REPORT_SUCCESS,
  USER_CANCEL_REPORT_FAIL,
  USER_GET_CLIENTS_SIMPLE_REQUEST,
  USER_GET_CLIENTS_SIMPLE_SUCCESS,
  USER_GET_CLIENTS_SIMPLE_FAIL,
  USER_CLIENT_UPDATE_EMPLOYEE_REQUEST,
  USER_CLIENT_UPDATE_EMPLOYEE_SUCCESS,
  USER_CLIENT_UPDATE_EMPLOYEE_FAIL,
  USER_GET_CLIENT_EMPLOYEES_REQUEST,
  USER_GET_CLIENT_EMPLOYEES_SUCCESS,
  USER_GET_CLIENT_EMPLOYEES_FAIL,
  USER_GET_CLIENT_EMPLOYEES_SIMPLE_REQUEST,
  USER_GET_CLIENT_EMPLOYEES_SIMPLE_SUCCESS,
  USER_GET_CLIENT_EMPLOYEES_SIMPLE_FAIL,
  USER_CLIENT_EMPLOYEE_ATTENDEE_COURSE_REGISTER_REQUEST,
  USER_CLIENT_EMPLOYEE_ATTENDEE_COURSE_REGISTER_SUCCESS,
  USER_CLIENT_EMPLOYEE_ATTENDEE_COURSE_REGISTER_FAIL,
  USER_VERIFY_EMAIL_TOKEN_REQUEST,
  USER_VERIFY_EMAIL_TOKEN_SUCCESS,
  USER_VERIFY_EMAIL_TOKEN_FAIL,
  USER_REGISTERED_COURSES_PUBLIC_UPDATE_STATUS_REQUEST,
  USER_REGISTERED_COURSES_PUBLIC_UPDATE_STATUS_SUCCESS,
  USER_REGISTERED_COURSES_PUBLIC_UPDATE_STATUS_FAIL,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_FORGOT_PASSWORD_FAIL,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
} from "../constants/userConstants";

export const login =
  (email, password, selectedAccountType = null) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_LOGIN_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        "/api/users/login",
        { email, password, selectedAccountType }, // Include account type if provided
        config
      );

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });

      if (data.userInfo) {
        // Save userInfo to localStorage
        localStorage.setItem("userInfo", JSON.stringify(data.userInfo));
      } else {
        // Clear userInfo for multiple accounts case
        localStorage.removeItem("userInfo");
      }
    } catch (error) {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");

  dispatch({ type: USER_LOGOUT });
  dispatch({ type: USER_DETAILS_RESET });

  document.location.href = "/login";
};

export const register =
  (
    firstName,
    lastName,
    email,
    password,
    birthDate,
    phone,
    street,
    zip,
    city,
    state,
    country
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_REGISTER_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        "/api/users",
        {
          firstName,
          lastName,
          email,
          password,
          birthDate,
          phone,
          street,
          zip,
          city,
          state,
          country,
        },
        config
      );

      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data,
      });

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });

      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const registerNewEmployee =
  (
    firstName,
    lastName,
    email,
    password,
    birthDate,
    phone,
    street,
    zip,
    city,
    state,
    country
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_REGISTER_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        "/api/users",
        {
          firstName,
          lastName,
          email,
          password,
          birthDate,
          phone,
          street,
          zip,
          city,
          state,
          country,
        },
        config
      );

      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getUserDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/users/${id}`, config);

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const updateUserProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(`/api/users/profile`, user, config);

    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload: message,
    });
  }
};

export const listUsers =
  (
    keyword = "",
    pageNumber = "",
    pageSize = "",
    sortField = "",
    sortOrder = "",
    status = ""
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: USER_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // Constructing the query string
      let query = `/api/users?keyword=${encodeURIComponent(keyword)}`;
      query += `&pageNumber=${pageNumber}&pageSize=${pageSize}`;

      if (sortField) query += `&sortField=${encodeURIComponent(sortField)}`;
      if (sortOrder) query += `&sortOrder=${encodeURIComponent(sortOrder)}`;
      if (status) query += `&status=${encodeURIComponent(status)}`;

      const { data } = await axios.get(query, config);

      dispatch({
        type: USER_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/users/${id}`, config);

    dispatch({ type: USER_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_DELETE_FAIL,
      payload: message,
    });
  }
};

export const updateUser = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(`/api/users/${user._id}`, user, config);

    dispatch({ type: USER_UPDATE_SUCCESS });

    dispatch({ type: USER_DETAILS_SUCCESS, payload: data });

    dispatch({ type: USER_DETAILS_RESET });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_UPDATE_FAIL,
      payload: message,
    });
  }
};

export const updateUserStatus =
  (userId, newStatus) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_STATUS_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/users/${userId}/status`,
        { status: newStatus }, // Send the new status in the request body
        config
      );

      dispatch({
        type: USER_STATUS_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_STATUS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const registerUserVerification =
  (
    firstName,
    lastName,
    email,
    password,
    clientType,
    companyName,
    companyCode,
    vatCode,
    phone,
    street,
    zip,
    city,
    state,
    country,
    profession
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_REGISTER_VERIFICATION_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        "/api/users/register",
        {
          firstName,
          lastName,
          email,
          password,
          clientType,
          companyName,
          companyCode,
          vatCode,
          phone,
          street,
          zip,
          city,
          state,
          country,
          profession,
        },
        config
      );

      console.log("data", data);

      dispatch({
        type: USER_REGISTER_VERIFICATION_SUCCESS,
        payload: data,
      });

      // dispatch({
      //   type: USER_LOGIN_SUCCESS,
      //   payload: data,
      // });

      // localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: USER_REGISTER_VERIFICATION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const verifyEmail =
  (email, verificationCode, verificationTokenIdentify) => async (dispatch) => {
    try {
      dispatch({
        type: USER_VERIFY_EMAIL_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        "/api/users/verify",
        { email, verificationCode, verificationTokenIdentify },
        config
      );

      dispatch({
        type: USER_VERIFY_EMAIL_SUCCESS,
        payload: data,
      });

      // dispatch({
      //   type: USER_LOGIN_SUCCESS,
      //   payload: data,
      // });

      // localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: USER_VERIFY_EMAIL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const verifyEmailWithToken = (token) => async (dispatch) => {
  try {
    dispatch({
      type: USER_VERIFY_EMAIL_TOKEN_REQUEST,
    });

    // console.log("received token", token);

    // Change to use query parameters
    const { data } = await axios.post(`/api/users/verify-token?token=${token}`);

    dispatch({
      type: USER_VERIFY_EMAIL_TOKEN_SUCCESS,
      payload: data,
    });

    // dispatch({
    //   type: USER_LOGIN_SUCCESS,
    //   payload: data,
    // });

    // console.log("after data", data);

    // localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_VERIFY_EMAIL_TOKEN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getUserRegisteredCoursesPublicAction =
  (userId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_GET_REGISTERED_COURSES_PUBLIC_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/users/${userId}/user-courses-public`,
        config
      );

      dispatch({
        type: USER_GET_REGISTERED_COURSES_PUBLIC_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_GET_REGISTERED_COURSES_PUBLIC_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getUserReports =
  (userId, page = 1) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: USER_CLIENT_GET_REPORTS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      };

      const { data } = await axios.get(
        `/api/users/${userId}/reports?page=${page}`,
        config
      );

      dispatch({
        type: USER_CLIENT_GET_REPORTS_SUCCESS,
        payload: {
          reports: data.reports,
          pages: data.pages,
          totalReports: data.totalReports, // Make sure this is being sent
        },
      });
    } catch (error) {
      dispatch({
        type: USER_CLIENT_GET_REPORTS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getUsersClients =
  (
    keyword = "",
    pageNumber = "",
    pageSize = "",
    sortField = "",
    sortOrder = ""
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: USER_GET_CLIENTS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // Constructing the query string

      let query = `/api/users/clients?keyword=${encodeURIComponent(keyword)}`;
      query += `&pageNumber=${pageNumber}&pageSize=${pageSize}`;

      if (sortField) query += `&sortField=${encodeURIComponent(sortField)}`;
      if (sortOrder) query += `&sortOrder=${encodeURIComponent(sortOrder)}`;

      const { data } = await axios.get(query, config);

      dispatch({
        type: USER_GET_CLIENTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_GET_CLIENTS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getUserClientDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_GET_CLIENT_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/users/clients/${id}`, config);

    dispatch({
      type: USER_GET_CLIENT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_GET_CLIENT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getUserSingleReport =
  (userId, reportId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_GET_SINGLE_REPORT_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/users/${userId}/reports/${reportId}`,
        config
      );

      dispatch({
        type: USER_GET_SINGLE_REPORT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_GET_SINGLE_REPORT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getUserSingleEvent =
  (id, coursePublicId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_GET_SINGLE_EVENT_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/users/${id}/user-courses-public/${coursePublicId}`,
        config
      );

      dispatch({
        type: USER_GET_SINGLE_EVENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_GET_SINGLE_EVENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateUserClientDetails =
  (payload) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_UPDATE_DETAILS_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const id = userInfo._id;

      const { data } = await axios.put(
        `/api/users/clients/${id}`,
        payload,
        config
      );

      console.log("data", data);

      dispatch({
        type: USER_UPDATE_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_UPDATE_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const cancelReport =
  (userId, reportId, cancelReason) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_CANCEL_REPORT_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // Include cancelReason in the body of the PUT request
      const { data } = await axios.put(
        `/api/users/${userId}/reports/${reportId}/cancel`,
        { cancelReason }, // This is the body of the PUT request
        config
      );

      dispatch({
        type: USER_CANCEL_REPORT_SUCCESS,
        payload: data, // optionally handle data
      });
    } catch (error) {
      dispatch({
        type: USER_CANCEL_REPORT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getUserClientsSimpleList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_GET_CLIENTS_SIMPLE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/users/clients-simple`, config);

    dispatch({
      type: USER_GET_CLIENTS_SIMPLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_GET_CLIENTS_SIMPLE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateUserClientEmployee =
  (clientId, employeeId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_CLIENT_UPDATE_EMPLOYEE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/users/clients/${clientId}/manager`,
        { employeeId },
        config
      );

      dispatch({
        type: USER_CLIENT_UPDATE_EMPLOYEE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_CLIENT_UPDATE_EMPLOYEE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getUserEmployees =
  (userId, page = 1) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: USER_GET_CLIENT_EMPLOYEES_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      };

      const { data } = await axios.get(
        `/api/users/${userId}/employees?page=${page}`,
        config
      );

      dispatch({
        type: USER_GET_CLIENT_EMPLOYEES_SUCCESS,
        payload: {
          employees: data.employees,
          pages: data.pages,
          totalEmployees: data.totalEmployees, // Make sure this is being sent
        },
      });
    } catch (error) {
      dispatch({
        type: USER_GET_CLIENT_EMPLOYEES_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getUserEmployeesSimpleList =
  (userId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_GET_CLIENT_EMPLOYEES_SIMPLE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/users/${userId}/employees-simple`,
        config
      );

      dispatch({
        type: USER_GET_CLIENT_EMPLOYEES_SIMPLE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_GET_CLIENT_EMPLOYEES_SIMPLE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const registerUserClientEmployeeAttendeeCourse =
  (
    courseId,
    firstName,
    lastName,
    email,
    profession,
    attendeeType,
    client,
    price,
    companyName,
    existingAttendee,
    phone,
    userId
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_CLIENT_EMPLOYEE_ATTENDEE_COURSE_REGISTER_REQUEST,
      });

      console.log("REGISTER USER TO COURSE ACTION", {
        courseId,
        firstName,
        lastName,
        email,
        profession,
        attendeeType,
        client,
        price,
        companyName,
        existingAttendee,
        phone,
        userId,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/users/${userId}/events/register-employee-course`,
        {
          courseId,
          firstName,
          lastName,
          email,
          profession,
          attendeeType,
          client,
          price,
          companyName,
          existingAttendee,
          phone,
          userId,
        },
        config
      );

      console.log("ACTION DATA", data);

      dispatch({
        type: USER_CLIENT_EMPLOYEE_ATTENDEE_COURSE_REGISTER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_CLIENT_EMPLOYEE_ATTENDEE_COURSE_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateUserRegisteredCoursesPublicStatus =
  (id, coursePublicId, status, cancelReason, attendeeId) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_REGISTERED_COURSES_PUBLIC_UPDATE_STATUS_REQUEST,
      });

      console.log(
        "DISPATCHING IN ACTION: ",
        id,
        coursePublicId,
        status,
        cancelReason,
        attendeeId
      );

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/users/${id}/user-courses-public/${coursePublicId}/status`,
        { status, cancelReason, attendeeId },
        config
      );

      console.log("DATA IN ACTION: ", data);

      dispatch({
        type: USER_REGISTERED_COURSES_PUBLIC_UPDATE_STATUS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_REGISTERED_COURSES_PUBLIC_UPDATE_STATUS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({
      type: USER_FORGOT_PASSWORD_REQUEST,
    });

    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // };

    const { data } = await axios.post("/api/users/forgot-password", { email });

    dispatch({
      type: USER_FORGOT_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_FORGOT_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const resetPassword = (token, newPassword) => async (dispatch) => {
  try {
    dispatch({
      type: USER_RESET_PASSWORD_REQUEST,
    });

    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // };

    // console.log("token", token);

    // Correcting the URL to include the token as a query parameter
    const { data } = await axios.put(`/api/users/reset-password`, {
      token,
      newPassword,
    });

    dispatch({
      type: USER_RESET_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_RESET_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
