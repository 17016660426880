import React from "react";
import { IconButton, Icon, Tooltip } from "@chakra-ui/react";
import { TbEye, TbFileDownload } from "react-icons/tb";
import {
  backgroundLight,
  primary,
  textSecondary,
  white,
} from "../../utils/colors";

const IconButtonComponent = ({
  tooltipLabel,
  noTooltip,
  buttonIcon,
  buttonHoverBg,
  buttonHoverColor,
  buttonBg,
  buttonColor,
  onClick,
}) => {
  return (
    <>
      <Tooltip
        p="0.5rem 1rem"
        borderRadius="1rem"
        label={tooltipLabel}
        placement="top"
        isDisabled={noTooltip}
      >
        <IconButton
          size="sm"
          bg={buttonBg || backgroundLight}
          color={buttonColor || textSecondary}
          borderRadius="10rem"
          onClick={onClick}
          _hover={{
            bg: buttonHoverBg || primary,
            color: buttonHoverColor || white,
          }}
        >
          <Icon as={buttonIcon} fontSize="1rem" />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default IconButtonComponent;
