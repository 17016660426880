import React, { useState } from "react";
import { Flex, Text, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import TooltipText from "../../../../../components/partials/TooltipText";
import dayjs from "dayjs";
import {
  greenVivid,
  newBorder,
  textPrimary,
  textSecondary,
} from "../../../../../utils/colors";
import { TbEye, TbFileDownload } from "react-icons/tb";
import IconButtonComponent from "../../../../../components/buttons/IconButtonComponent";
import ImageViewer from "../../../../../components/popovers/ImageViewer";

const ReportSurgeryInfo = ({ data }) => {
  const { t } = useTranslation();
  const toast = useToast();

  // 🔹 State to control the ImageViewer
  const [isImageViewerOpen, setImageViewerOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageTitle, setImageTitle] = useState("");

  const openImageViewer = (image, title) => {
    setSelectedImage(image);
    setImageTitle(title);
    setImageViewerOpen(true);
  };

  const closeImageViewer = () => {
    setImageViewerOpen(false);
    setSelectedImage(null);
  };

  const handleViewImageBefore = () => {
    if (!data?.beforeXrayImage) {
      toast({
        title: t("implantForm.xRayViewError"),

        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    openImageViewer(
      data?.beforeXrayImage,
      t("implantForm.imageXrayBeforeSurgery")
    );
  };

  const handleViewImageFailure = () => {
    if (!data?.afterXrayImage) {
      toast({
        title: t("implantForm.xRayViewError"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    openImageViewer(data?.afterXrayImage, t("implantForm.imageXrayFailure"));
  };

  const handleDownloadImageBefore = async () => {
    if (!data?.beforeXrayImage) {
      toast({
        title: "Error",
        description: "No before-surgery X-ray image available.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      // Simulate an error (for testing)
      // throw new Error("Fake error for testing!");

      const response = await fetch(data?.beforeXrayImage, { mode: "cors" });
      if (!response.ok) throw new Error("Failed to fetch the image.");

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = data?.beforeXrayImage.split("/").pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      toast({
        title: t("implantForm.xRayDownloadSuccess"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error downloading the image:", error);
      toast({
        title: t("implantForm.xRayDownloadError"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDownloadImageFailure = async () => {
    if (!data?.afterXrayImage) {
      console.warn("No failure X-ray image available.");
      return;
    }

    try {
      const response = await fetch(data?.afterXrayImage, { mode: "cors" }); // Fetch image
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = data?.afterXrayImage.split("/").pop(); // Extract filename dynamically
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the image:", error);
    }
  };

  const infoArray = [
    {
      label: "Tooth",
      value: (
        <TooltipText
          icon="TbDental"
          label={t("implantForm.teeth")}
          value={data?.tooth}
          iconText
          noTooltip
        />
      ),
    },

    {
      label: "SurgeryDate",
      value: (
        <TooltipText
          icon="TbCalendar"
          label={t("implantForm.surgeryDate")}
          value={
            data?.surgeryDate === null
              ? "-"
              : dayjs(data?.surgeryDate).format("D MMMM, YYYY")
          }
          iconText
          noTooltip
        />
      ),
    },

    {
      label: "FailureDate",
      value: (
        <TooltipText
          icon="TbCalendar"
          label={t("implantForm.failureDate")}
          value={
            data?.failureDate === null
              ? "-"
              : dayjs(data?.failureDate).format("D MMMM, YYYY")
          }
          iconText
          noTooltip
        />
      ),
    },
  ];

  const secondInfoArray = [
    {
      label: "Reason",
      value: (
        <TooltipText
          icon="TbMapPin"
          label={t("implantForm.reason")}
          value={t(`failReason.${data?.reason}`)}
          iconText
          maxW="200px"
          noTooltip
        />
      ),
    },

    {
      label: "Surgeon",
      value: (
        <TooltipText
          icon="TbBuilding"
          label={t("implantForm.surgeon")}
          value={data?.surgeonFirstName + " " + data?.surgeonLastName}
          iconText
          maxW="200px"
          noTooltip
        />
      ),
    },
  ];

  return (
    <Flex w="100%" direction="column">
      <Flex
        w="100%"
        justify="space-between"
        p={["0 0rem 0rem", "0 2rem 0rem"]}
        gap="2rem"
        direction={["column", "row"]}
      >
        <Flex w={["100%", "50%"]} direction="column">
          {infoArray.map((item, index) => (
            <Flex key={index} alignItems="center">
              {item.value && (
                <Flex p="0.35rem 0" alignItems="center">
                  {item.value}
                </Flex>
              )}
            </Flex>
          ))}
        </Flex>

        <Flex w={["100%", "50%"]} direction="column">
          {secondInfoArray.map((item, index) => (
            <Flex key={index} alignItems="center">
              {item.value && (
                <Flex p="0.35rem 0" alignItems="center">
                  {item.value}
                </Flex>
              )}
            </Flex>
          ))}
        </Flex>
      </Flex>

      <Flex w="100%" p={["1rem 0rem 0", "1rem 2rem 0"]} direction="column">
        <Flex
          w="100%"
          borderTop={`1px solid ${newBorder}`}
          justify="space-between"
          gap={["0rem", "2rem"]}
          p="1rem 0 0"
          direction={["column", "row"]}
        >
          {/* BEFORE XRAY START */}
          <Flex
            align="center"
            p="0 0rem 0 0"
            m="0 0 0.7rem"
            justify="flex-start"
            w="100%"
          >
            <Text
              fontSize="sm"
              color={textSecondary}
              fontWeight="500"
              mr="0.5rem"
            >
              {t("implantForm.imageXrayBeforeSurgery")}:{" "}
            </Text>
            <Flex align="center" justify="flex-start" gap="0.5rem">
              <IconButtonComponent
                tooltipLabel={t("implantForm.viewXrayTooltip")}
                buttonIcon={TbEye}
                onClick={handleViewImageBefore}
              />
              <IconButtonComponent
                tooltipLabel={t("implantForm.downloadXrayTooltip")}
                buttonIcon={TbFileDownload}
                buttonHoverBg={greenVivid}
                buttonHoverColor={textPrimary}
                onClick={handleDownloadImageBefore}
              />
            </Flex>
          </Flex>
          {/* BEFORE XRAY END */}

          {/* FAILURE XRAY START */}
          <Flex
            align="center"
            p="0 0rem 0 0"
            m="0 0 0.7rem"
            justify="flex-start"
            w="100%"
          >
            <Text
              fontSize="sm"
              color={textSecondary}
              fontWeight="500"
              mr="0.5rem"
            >
              {t("implantForm.imageXrayFailure")}:{" "}
            </Text>
            <Flex align="center" justify="flex-start" gap="0.5rem">
              <IconButtonComponent
                tooltipLabel={t("implantForm.viewXrayTooltip")}
                buttonIcon={TbEye}
                onClick={handleViewImageFailure}
              />
              <IconButtonComponent
                tooltipLabel={t("implantForm.downloadXrayTooltip")}
                buttonIcon={TbFileDownload}
                buttonHoverBg={greenVivid}
                buttonHoverColor={textPrimary}
                onClick={handleDownloadImageFailure}
              />
            </Flex>
          </Flex>
          {/* FAILURE XRAY END */}
        </Flex>
      </Flex>

      {/* 🔹 Reusable ImageViewer */}
      <ImageViewer
        isOpen={isImageViewerOpen}
        onClose={closeImageViewer}
        imageUrl={selectedImage}
        title={imageTitle}
      />
    </Flex>
  );
};

export default ReportSurgeryInfo;
