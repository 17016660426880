import {
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_RESET,
  USER_DETAILS_SUCCESS,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_RESET,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_RESET,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_RESET,
  USER_UPDATE_RESET,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_PROFILE_RESET,
  USER_STATUS_UPDATE_REQUEST,
  USER_STATUS_UPDATE_SUCCESS,
  USER_STATUS_UPDATE_FAIL,
  USER_STATUS_UPDATE_RESET,
  USER_REGISTER_VERIFICATION_REQUEST,
  USER_REGISTER_VERIFICATION_SUCCESS,
  USER_REGISTER_VERIFICATION_FAIL,
  USER_REGISTER_VERIFICATION_RESET,
  USER_VERIFY_EMAIL_REQUEST,
  USER_VERIFY_EMAIL_SUCCESS,
  USER_VERIFY_EMAIL_FAIL,
  USER_VERIFY_EMAIL_RESET,
  USER_GET_REGISTERED_COURSES_PUBLIC_REQUEST,
  USER_GET_REGISTERED_COURSES_PUBLIC_SUCCESS,
  USER_GET_REGISTERED_COURSES_PUBLIC_FAIL,
  USER_GET_REGISTERED_COURSES_PUBLIC_RESET,
  USER_CLIENT_GET_REPORTS_REQUEST,
  USER_CLIENT_GET_REPORTS_SUCCESS,
  USER_CLIENT_GET_REPORTS_FAIL,
  USER_CLIENT_GET_REPORTS_RESET,
  USER_GET_CLIENTS_REQUEST,
  USER_GET_CLIENTS_SUCCESS,
  USER_GET_CLIENTS_FAIL,
  USER_GET_CLIENTS_RESET,
  USER_GET_CLIENT_DETAILS_REQUEST,
  USER_GET_CLIENT_DETAILS_SUCCESS,
  USER_GET_CLIENT_DETAILS_FAIL,
  USER_GET_CLIENT_DETAILS_RESET,
  USER_GET_SINGLE_REPORT_REQUEST,
  USER_GET_SINGLE_REPORT_SUCCESS,
  USER_GET_SINGLE_REPORT_FAIL,
  USER_GET_SINGLE_REPORT_RESET,
  USER_GET_SINGLE_EVENT_REQUEST,
  USER_GET_SINGLE_EVENT_SUCCESS,
  USER_GET_SINGLE_EVENT_FAIL,
  USER_GET_SINGLE_EVENT_RESET,
  USER_UPDATE_DETAILS_REQUEST,
  USER_UPDATE_DETAILS_SUCCESS,
  USER_UPDATE_DETAILS_FAIL,
  USER_UPDATE_DETAILS_RESET,
  USER_CANCEL_REPORT_REQUEST,
  USER_CANCEL_REPORT_SUCCESS,
  USER_CANCEL_REPORT_FAIL,
  USER_CANCEL_REPORT_RESET,
  USER_GET_CLIENTS_SIMPLE_REQUEST,
  USER_GET_CLIENTS_SIMPLE_SUCCESS,
  USER_GET_CLIENTS_SIMPLE_FAIL,
  USER_GET_CLIENTS_SIMPLE_RESET,
  USER_CLIENT_UPDATE_EMPLOYEE_REQUEST,
  USER_CLIENT_UPDATE_EMPLOYEE_SUCCESS,
  USER_CLIENT_UPDATE_EMPLOYEE_FAIL,
  USER_CLIENT_UPDATE_EMPLOYEE_RESET,
  USER_GET_CLIENT_EMPLOYEES_REQUEST,
  USER_GET_CLIENT_EMPLOYEES_SUCCESS,
  USER_GET_CLIENT_EMPLOYEES_FAIL,
  USER_GET_CLIENT_EMPLOYEES_RESET,
  USER_GET_CLIENT_EMPLOYEES_SIMPLE_REQUEST,
  USER_GET_CLIENT_EMPLOYEES_SIMPLE_SUCCESS,
  USER_GET_CLIENT_EMPLOYEES_SIMPLE_FAIL,
  USER_GET_CLIENT_EMPLOYEES_SIMPLE_RESET,
  USER_CLIENT_EMPLOYEE_ATTENDEE_COURSE_REGISTER_REQUEST,
  USER_CLIENT_EMPLOYEE_ATTENDEE_COURSE_REGISTER_SUCCESS,
  USER_CLIENT_EMPLOYEE_ATTENDEE_COURSE_REGISTER_FAIL,
  USER_CLIENT_EMPLOYEE_ATTENDEE_COURSE_REGISTER_RESET,
  USER_VERIFY_EMAIL_TOKEN_REQUEST,
  USER_VERIFY_EMAIL_TOKEN_SUCCESS,
  USER_VERIFY_EMAIL_TOKEN_FAIL,
  USER_VERIFY_EMAIL_TOKEN_RESET,
  USER_REGISTERED_COURSES_PUBLIC_UPDATE_STATUS_REQUEST,
  USER_REGISTERED_COURSES_PUBLIC_UPDATE_STATUS_SUCCESS,
  USER_REGISTERED_COURSES_PUBLIC_UPDATE_STATUS_FAIL,
  USER_REGISTERED_COURSES_PUBLIC_UPDATE_STATUS_RESET,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_FORGOT_PASSWORD_FAIL,
  USER_FORGOT_PASSWORD_RESET,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
  USER_RESET_PASSWORD_RESET,
  USER_LOGIN_MULTI_REQUEST,
  USER_LOGIN_MULTI_SUCCESS,
  USER_LOGIN_MULTI_FAIL,
  USER_LOGIN_MULTI_RESET,
} from "../constants/userConstants";

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload.userInfo || null,
        success: true,
        multipleAccounts: action.payload.multipleAccounts || false,
      };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGIN_RESET:
      return {};
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userAuthReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { ...state, loading: true, error: null };

    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload.userInfo || null,
        success: true,
        multipleAccounts: action.payload.multipleAccounts || false,
      };

    case USER_LOGIN_FAIL:
      return { ...state, loading: false, error: action.payload };

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    case USER_REGISTER_RESET:
      return {};
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userNewEmployeeRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, success: true, user: action.payload };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    case USER_REGISTER_RESET:
      return {};
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userDetailsReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { ...state, loading: true };
    case USER_DETAILS_SUCCESS:
      return { loading: false, user: action.payload };
    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case USER_DETAILS_RESET:
      return { user: {} };
    default:
      return state;
  }
};

export const userUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true };
    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    case USER_UPDATE_PROFILE_RESET:
      return {};
    default:
      return state;
  }
};

export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true, users: [] };
    case USER_LIST_SUCCESS:
      return {
        loading: false,
        users: action.payload.users,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loading: true };
    case USER_DELETE_SUCCESS:
      return { loading: false, success: true };
    case USER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case USER_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const userUpdateReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true };
    case USER_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userStatusUpdateReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_STATUS_UPDATE_REQUEST:
      return { loading: true };
    case USER_STATUS_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case USER_STATUS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case USER_STATUS_UPDATE_RESET:
      return {
        user: {},
      };
    default:
      return state;
  }
};

export const userRegisterVerificationReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_VERIFICATION_REQUEST:
      return { loading: true };
    case USER_REGISTER_VERIFICATION_SUCCESS:
      return { loading: false, userInfo: action.payload, success: true };
    case USER_REGISTER_VERIFICATION_FAIL:
      return { loading: false, error: action.payload };
    case USER_REGISTER_VERIFICATION_RESET:
      return {};
    default:
      return state;
  }
};

export const userVerifyEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_VERIFY_EMAIL_REQUEST:
      return { loading: true };
    case USER_VERIFY_EMAIL_SUCCESS:
      return { loading: false, userInfo: action.payload, success: true };
    case USER_VERIFY_EMAIL_FAIL:
      return { loading: false, error: action.payload };
    case USER_VERIFY_EMAIL_RESET:
      return {};
    default:
      return state;
  }
};

export const userVerifyEmailTokenReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_VERIFY_EMAIL_TOKEN_REQUEST:
      return { loading: true };
    case USER_VERIFY_EMAIL_TOKEN_SUCCESS:
      return { loading: false, userInfo: action.payload, success: true };
    case USER_VERIFY_EMAIL_TOKEN_FAIL:
      return { loading: false, error: action.payload };
    case USER_VERIFY_EMAIL_TOKEN_RESET:
      return {};
    default:
      return state;
  }
};

export const userRegisteredCoursesPublicReducer = (
  state = { coursesRegistered: [] },
  action
) => {
  switch (action.type) {
    case USER_GET_REGISTERED_COURSES_PUBLIC_REQUEST:
      return { loading: true, coursesRegistered: [] };
    case USER_GET_REGISTERED_COURSES_PUBLIC_SUCCESS:
      return { loading: false, coursesRegistered: action.payload };
    case USER_GET_REGISTERED_COURSES_PUBLIC_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userClientGetReportsReducer = (
  state = {
    userReports: [],
    loading: false,
    error: null,
    pages: 1,
    totalReports: 0,
  },
  action
) => {
  switch (action.type) {
    case USER_CLIENT_GET_REPORTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_CLIENT_GET_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        userReports: [...state.userReports, ...action.payload.reports], // Appending new reports to existing list
        pages: action.payload.pages,
        totalReports: action.payload.totalReports, // Setting total reports from action payload
      };
    case USER_CLIENT_GET_REPORTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case USER_CLIENT_GET_REPORTS_RESET:
      return {
        userReports: [],
        loading: false,
        error: null,
        pages: 1,
        totalReports: 0, // Resetting totalReports on reset
      };
    default:
      return state;
  }
};

export const usersGetClientsReducer = (state = { clients: [] }, action) => {
  switch (action.type) {
    case USER_GET_CLIENTS_REQUEST:
      return { loading: true, clients: [] };
    case USER_GET_CLIENTS_SUCCESS:
      return {
        loading: false,
        clients: action.payload.clients,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case USER_GET_CLIENTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userGetClientDetailsReducer = (
  state = {
    client: { totalReports: 0, totalCourses: 0 },
    loading: false,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case USER_GET_CLIENT_DETAILS_REQUEST:
      return { ...state, loading: true };
    case USER_GET_CLIENT_DETAILS_SUCCESS:
      return { loading: false, client: action.payload };
    case USER_GET_CLIENT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case USER_GET_CLIENT_DETAILS_RESET:
      return { client: { totalReports: 0, totalCourses: 0 } };
    default:
      return state;
  }
};

// export const userGetClientDetailsReducer = (state = { client: {} }, action) => {
//   switch (action.type) {
//     case USER_GET_CLIENT_DETAILS_REQUEST:
//       return { ...state, loading: true };
//     case USER_GET_CLIENT_DETAILS_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         client: {
//           ...action.payload,
//           totalReports: action.payload.totalReports,
//           lastThreeReports: action.payload.lastThreeReports,
//           totalCourses: action.payload.totalCourses,
//           lastThreeCourses: action.payload.lastThreeCourses,
//         },
//       };
//     case USER_GET_CLIENT_DETAILS_FAIL:
//       return { ...state, loading: false, error: action.payload };
//     case USER_GET_CLIENT_DETAILS_RESET:
//       return { client: {} };
//     default:
//       return state;
//   }
// };

export const userGetSingleReportReducer = (state = { report: {} }, action) => {
  switch (action.type) {
    case USER_GET_SINGLE_REPORT_REQUEST:
      return { ...state, loading: true };
    case USER_GET_SINGLE_REPORT_SUCCESS:
      return { loading: false, report: action.payload };
    case USER_GET_SINGLE_REPORT_FAIL:
      return { loading: false, error: action.payload };
    case USER_GET_SINGLE_REPORT_RESET:
      return { report: {} };
    default:
      return state;
  }
};

export const userGetSingleEventReducer = (state = { course: {} }, action) => {
  switch (action.type) {
    case USER_GET_SINGLE_EVENT_REQUEST:
      return { ...state, loading: true };
    case USER_GET_SINGLE_EVENT_SUCCESS:
      return {
        loading: false,
        course: action.payload,
        // attendee: action.payload.attendee,
      };
    case USER_GET_SINGLE_EVENT_FAIL:
      return { loading: false, error: action.payload };
    case USER_GET_SINGLE_EVENT_RESET:
      return { event: {} };
    default:
      return state;
  }
};

export const userUpdateDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_DETAILS_REQUEST:
      return { loading: true };
    case USER_UPDATE_DETAILS_SUCCESS:
      return {
        loading: false,
        success: true,
        updatedAt: action.payload.updatedAt,
      };
    case USER_UPDATE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case USER_UPDATE_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

export const userCancelReportReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CANCEL_REPORT_REQUEST:
      return { loading: true };
    case USER_CANCEL_REPORT_SUCCESS:
      return { loading: false, success: true };
    case USER_CANCEL_REPORT_FAIL:
      return { loading: false, error: action.payload };
    case USER_CANCEL_REPORT_RESET:
      return {};
    default:
      return state;
  }
};

export const userGetClientsSimpleReducer = (
  state = { clients: [] },
  action
) => {
  switch (action.type) {
    case USER_GET_CLIENTS_SIMPLE_REQUEST:
      return { loading: true, clients: [] };
    case USER_GET_CLIENTS_SIMPLE_SUCCESS:
      return {
        loading: false,
        clients: action.payload.clients,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case USER_GET_CLIENTS_SIMPLE_FAIL:
      return { loading: false, error: action.payload };
    case USER_GET_CLIENTS_SIMPLE_RESET:
      return { clients: [] };
    default:
      return state;
  }
};

export const userClientUpdateEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CLIENT_UPDATE_EMPLOYEE_REQUEST:
      return { loading: true };
    case USER_CLIENT_UPDATE_EMPLOYEE_SUCCESS:
      return { loading: false, success: true };
    case USER_CLIENT_UPDATE_EMPLOYEE_FAIL:
      return { loading: false, error: action.payload };
    case USER_CLIENT_UPDATE_EMPLOYEE_RESET:
      return {};
    default:
      return state;
  }
};

export const userClientGetEmployeesReducer = (
  state = {
    employees: [],
    loading: false,
    error: null,
    pages: 1,
    totalEmployees: 0,
  },
  action
) => {
  switch (action.type) {
    case USER_GET_CLIENT_EMPLOYEES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_GET_CLIENT_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        employees: [...state.employees, ...action.payload.employees], // Appending new employees to existing list
        pages: action.payload.pages,
        totalEmployees: action.payload.totalEmployees, // Setting total employees from action payload
      };
    case USER_GET_CLIENT_EMPLOYEES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case USER_GET_CLIENT_EMPLOYEES_RESET:
      return {
        employees: [],
        loading: false,
        error: null,
        pages: 1,
        totalEmployees: 0, // Resetting totalReports on reset
      };
    default:
      return state;
  }
};

export const userClientGetEmployeesSimpleReducer = (
  state = { employees: [] },
  action
) => {
  switch (action.type) {
    case USER_GET_CLIENT_EMPLOYEES_SIMPLE_REQUEST:
      return { loading: true, employees: [] };
    case USER_GET_CLIENT_EMPLOYEES_SIMPLE_SUCCESS:
      return { loading: false, employees: action.payload.employees };
    case USER_GET_CLIENT_EMPLOYEES_SIMPLE_FAIL:
      return { loading: false, error: action.payload };
    case USER_GET_CLIENT_EMPLOYEES_SIMPLE_RESET:
      return { employees: [] };
    default:
      return state;
  }
};

export const userClientEmployeeAttendeeCourseRegisterReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case USER_CLIENT_EMPLOYEE_ATTENDEE_COURSE_REGISTER_REQUEST:
      return { loading: true };
    case USER_CLIENT_EMPLOYEE_ATTENDEE_COURSE_REGISTER_SUCCESS:
      return { loading: false, success: true };
    case USER_CLIENT_EMPLOYEE_ATTENDEE_COURSE_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    case USER_CLIENT_EMPLOYEE_ATTENDEE_COURSE_REGISTER_RESET:
      return {};
    default:
      return state;
  }
};

export const userRegisteredCoursesPublicUpdateStatusReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case USER_REGISTERED_COURSES_PUBLIC_UPDATE_STATUS_REQUEST:
      return { loading: true };
    case USER_REGISTERED_COURSES_PUBLIC_UPDATE_STATUS_SUCCESS:
      return { loading: false, success: true };
    case USER_REGISTERED_COURSES_PUBLIC_UPDATE_STATUS_FAIL:
      return { loading: false, error: action.payload };
    case USER_REGISTERED_COURSES_PUBLIC_UPDATE_STATUS_RESET:
      return {};
    default:
      return state;
  }
};

export const userForgotPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_FORGOT_PASSWORD_REQUEST:
      return { loading: true };
    case USER_FORGOT_PASSWORD_SUCCESS:
      return { loading: false, success: true };
    case USER_FORGOT_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    case USER_FORGOT_PASSWORD_RESET:
      return {};
    default:
      return state;
  }
};

export const userResetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_RESET_PASSWORD_REQUEST:
      return { loading: true };
    case USER_RESET_PASSWORD_SUCCESS:
      return { loading: false, success: true };
    case USER_RESET_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    case USER_RESET_PASSWORD_RESET:
      return {};
    default:
      return state;
  }
};
