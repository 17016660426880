import React from "react";
import { Flex, Heading, Text, Button, Stack, Box } from "@chakra-ui/react";
import {
  textPrimary,
  buttonYellowText,
  newBorder,
  greenVivid,
  primary,
  textSecondary,
  white,
  buttonRedText,
  buttonRed,
} from "../../../../../utils/colors";
import StatusButton from "../../../../../components/buttons/StatusButton";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { shade } from "polished";
import StatusButtonTiny from "../../../../../components/buttons/StatusButtonTiny";

const ReportActions = ({
  report,
  setShowCancelModal,
  setShowApproveModal,
  setShowCloseModal,
}) => {
  const { t } = useTranslation();

  // Determine author display name
  let reportStatusChangedAtName = "";

  if (report.status === "pending") {
    reportStatusChangedAtName = t(
      "implantFailureReports.reportStatusChangedAtPending"
    );
  } else if (report.status === "approved") {
    reportStatusChangedAtName = t(
      "implantFailureReports.reportStatusChangedAtApproved"
    );
  } else if (report.status === "rejected") {
    reportStatusChangedAtName = t(
      "implantFailureReports.reportStatusChangedAtRejected"
    );
  } else if (report.status === "cancelled") {
    reportStatusChangedAtName = t(
      "implantFailureReports.reportStatusChangedAtCancelled"
    );
  } else if (report.status === "closed") {
    reportStatusChangedAtName = t(
      "implantFailureReports.reportStatusChangedAtClosed"
    );
  } else {
    reportStatusChangedAtName = t(
      "implantFailureReports.reportStatusChangedAt"
    );
  }

  let reportStatusChangedBy = "";

  if (report.status === "pending") {
    reportStatusChangedBy = t(
      "implantFailureReports.reportStatusChangedByPending"
    );
  } else if (report.status === "approved") {
    reportStatusChangedBy = t(
      "implantFailureReports.reportStatusChangedByApproved"
    );
  } else if (report.status === "rejected") {
    reportStatusChangedBy = t(
      "implantFailureReports.reportStatusChangedByRejected"
    );
  } else if (report.status === "cancelled") {
    reportStatusChangedBy = t(
      "implantFailureReports.reportStatusChangedByCancelled"
    );
  } else if (report.status === "closed") {
    reportStatusChangedBy = t(
      "implantFailureReports.reportStatusChangedByClosed"
    );
  } else {
    reportStatusChangedBy = t("implantFailureReports.reportStatusChangedBy");
  }

  let authorName = "";

  if (report.authorType === "client") {
    if (report.clientType === "individual") {
      authorName =
        `${report.author.firstName} ${report.author.lastName}`.trim();
    } else {
      authorName = report.author.companyName;
    }
  } else if (report.authorType === "employee") {
    authorName = `${report.author.firstName} ${report.author.lastName}`.trim();
  }

  let authorType = "";
  if (report.authorType === "client") {
    authorType = t("form.client");
  } else {
    authorType = t("form.admin");
  }

  let cancelAuthorName = "";

  // if (report.cancelAuthor) {
  //   cancelAuthorName =
  //     `${report.cancelAuthor.firstName} ${report.cancelAuthor.lastName}`.trim();
  // }

  if (report.cancelAuthor) {
    if (report.clientType === "individual") {
      cancelAuthorName =
        `${report.cancelAuthor.firstName} ${report.cancelAuthor.lastName}`.trim();
    } else if (report.clientType === "company") {
      cancelAuthorName = report.cancelAuthor.companyName;
    } else {
      cancelAuthorName =
        report.cancelAuthor.firstName + " " + report.cancelAuthor.lastName;
    }
  }

  return (
    <>
      <Flex w="100%" p="0rem 2.5rem 4rem" direction="column">
        <Flex justify="space-between" w="100%">
          <Flex alignItems="center" m="0rem 0 1rem">
            <Heading
              fontSize="md"
              fontWeight="600"
              color={textPrimary}
              mr="1rem"
            >
              {t("implantFailureReports.reportStatusTitle")}
            </Heading>
          </Flex>
        </Flex>

        {/* NEW START */}
        <Flex
          w="100%"
          direction="column"
          m="0 auto"
          border={`1px solid ${newBorder}`}
          borderRadius="1rem"
          p="2rem 2rem"
          gap="0.25rem"
        >
          {/* REPORT STATUS */}
          <Box>
            <Flex align="center" justify="center" m="0 auto">
              <Text
                fontSize="sm"
                fontWeight="500"
                color={textSecondary}
                mr="0.25rem"
              >
                {t("implantFailureReports.reportStatusTitle")}:
              </Text>
              <StatusButtonTiny
                isUnclickable
                currentStatus={report.status}
                options="report"
                isSmall
              />
              {/* <Text fontSize="sm" fontWeight="500" color={textPrimary} ml={1}>
                {t(`status.${report.status}`)}
              </Text> */}
            </Flex>
          </Box>
          {/* REPORT STATUS DATE */}
          <Box>
            <Flex align="center" justify="center" m="0 auto">
              <Text fontSize="sm" fontWeight="500" color={textSecondary}>
                {reportStatusChangedAtName}:
              </Text>
              <Text fontSize="sm" fontWeight="500" color={textPrimary} ml={1}>
                {dayjs(report.statusDate).format("D MMMM YYYY, HH:mm")}
              </Text>
            </Flex>
          </Box>
          {/* REPORT STATUS UPDATED BY */}
          <Box>
            <Flex align="center" justify="center" m="0 auto">
              <Text fontSize="sm" fontWeight="500" color={textSecondary}>
                {reportStatusChangedBy}:
              </Text>

              {report && report.status === "pending" && (
                <Text fontSize="sm" fontWeight="500" color={textPrimary} ml={1}>
                  {authorType} - {authorName}
                </Text>
              )}

              {report && report.status === "approved" && (
                <Text fontSize="sm" fontWeight="500" color={textPrimary} ml={1}>
                  {authorType} - {cancelAuthorName}
                </Text>
              )}

              {report && report.status === "closed" && (
                <Text fontSize="sm" fontWeight="500" color={textPrimary} ml={1}>
                  {authorType} - {cancelAuthorName}
                </Text>
              )}

              {report && report.status === "rejected" && (
                <Text fontSize="sm" fontWeight="500" color={textPrimary} ml={1}>
                  {t("form.admin")} - {cancelAuthorName}
                </Text>
              )}

              {report && report.status === "cancelled" && (
                <Text fontSize="sm" fontWeight="500" color={textPrimary} ml={1}>
                  {t("form.client")} - {cancelAuthorName}
                </Text>
              )}
            </Flex>
          </Box>

          {/* REPORT STATUS AVAILABLE ACTIONS */}
          <Box m="0rem 0 0">
            <Flex align="center" justify="center" m="0 auto" gap="1rem">
              {report.status === "approved" && (
                <Flex
                  direction="column"
                  m="1.5rem auto 0"
                  align="center"
                  justify="center"
                >
                  <Text
                    fontSize="sm"
                    fontWeight="500"
                    color={textSecondary}
                    m="0rem auto 0.5rem"
                    textAlign="center"
                  >
                    {t("implantFailureReports.availableActions")}:
                  </Text>
                  <Button
                    size="lg"
                    borderRadius="10rem"
                    bg={greenVivid}
                    color={textPrimary}
                    fontSize="sm"
                    fontWeight="500"
                    border={`1px solid ${newBorder}`}
                    // fontWeight="500"
                    _hover={{
                      bg: shade(0.1, greenVivid),
                    }}
                    onClick={() => setShowCloseModal(true)}
                  >
                    {t("implantFailureReports.closeReport")}
                  </Button>
                </Flex>
              )}

              {report.status === "pending" && (
                <>
                  <Flex
                    direction="column"
                    m="1.5rem auto 0"
                    align="center"
                    justify="center"
                  >
                    <Text
                      fontSize="sm"
                      fontWeight="500"
                      color={textSecondary}
                      m="0rem auto 0.5rem"
                      textAlign="center"
                    >
                      {t("implantFailureReports.availableActions")}:
                    </Text>
                    <Flex align="center" justify="center" gap="1rem">
                      <Button
                        size="lg"
                        borderRadius="10rem"
                        bg={primary}
                        color={white}
                        fontSize="sm"
                        // fontWeight="500"
                        border={`1px solid ${primary}`}
                        onClick={() => setShowApproveModal(true)}
                        _hover={{
                          bg: shade(0.3, primary),
                        }}
                      >
                        {t("implantFailureReports.approveExchange")}
                      </Button>

                      <Button
                        size="lg"
                        borderRadius="10rem"
                        bg={white}
                        color={buttonRedText}
                        fontSize="sm"
                        fontWeight="500"
                        border={`1px solid ${newBorder}`}
                        // fontWeight="500"
                        onClick={() => setShowCancelModal(true)}
                        _hover={{
                          bg: buttonRed,
                        }}
                      >
                        {t("implantFailureReports.rejectExchange")}
                      </Button>
                    </Flex>
                  </Flex>
                </>
              )}
            </Flex>
          </Box>
          {/* REPORT CANCEL OR REJECT REASON */}
          {report.status === "rejected" || report.status === "cancelled" ? (
            <Box m="-0.25rem auto 0">
              <Text
                fontSize="sm"
                fontWeight="500"
                color={textSecondary}
                maxW="100%"
              >
                {report.status === "rejected"
                  ? t("implantFailureReports.rejectReason")
                  : t("implantFailureReports.cancelReason")}
                <Text
                  as="span"
                  color={textPrimary}
                  fontWeight="500"
                  ml={1}
                  textTransform="capitalize"
                >
                  {report.cancelReason}
                </Text>
              </Text>
            </Box>
          ) : null}

          {/* REPORT CLOSE DOCUMENT NUMBER  */}
          {report.status === "closed" && (
            <Box m="-0.25rem auto 0">
              <Text
                fontSize="sm"
                fontWeight="500"
                color={textSecondary}
                maxW="100%"
              >
                {t("implantFailureReports.documentNumber")}:
                <Text
                  as="span"
                  color={textPrimary}
                  fontWeight="500"
                  ml={1}
                  textTransform="capitalize"
                >
                  {report.documentNumber}
                </Text>
              </Text>
            </Box>
          )}
        </Flex>

        {/* NEW END */}
      </Flex>
    </>
  );
};

export default ReportActions;
