import React from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Flex,
  Button,
} from "@chakra-ui/react";
import {
  newBorder,
  white,
  primary,
  backgroundLight,
  textPrimary,
  textSecondary,
  primaryAlt,
} from "../../utils/colors";
import { useTranslation } from "react-i18next";

const SelectClientRegisterTypeReport = ({
  label,
  value,
  onChange,
  isRequired,
}) => {
  const { t } = useTranslation();

  //   const options = [
  //     { value: "registered", label: t("record.clientRegisterTypeRegistered") },
  //     {
  //       value: "unregistered",
  //       label: t("record.clientRegisterTypeUnregistered"),
  //     },
  //   ];

  // console.log("value", value);

  return (
    <Flex w="100%" bg={backgroundLight} borderRadius="1rem" p="8px">
      <Button
        w="50%"
        onClick={() => onChange("registered")}
        bg={value === "registered" ? white : "transparent"}
        color={value === "registered" ? textPrimary : textSecondary}
        border={newBorder}
        borderRadius="0.75rem"
        fontSize={"sm"}
        _hover={{
          bg: value === "registered" ? white : "transparent",
          color: value === "registered" ? textPrimary : textPrimary,
        }}
      >
        {t("record.clientRegisterTypeRegistered")}
      </Button>

      <Button
        w="50%"
        onClick={() => onChange("unregistered")}
        bg={value === "unregistered" ? white : "transparent"}
        color={value === "unregistered" ? textPrimary : textSecondary}
        border={newBorder}
        borderRadius="1rem"
        fontSize={"sm"}
        _hover={{
          bg: value === "unregistered" ? white : "transparent",
          color: value === "unregistered" ? textPrimary : textPrimary,
        }}
      >
        {t("record.clientRegisterTypeUnregistered")}
      </Button>
    </Flex>
  );
};

export default SelectClientRegisterTypeReport;
