import React, { useState, useEffect } from "react";
import { Flex, Box, Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import TooltipText from "../../../../../components/partials/TooltipText";
import { textPrimary } from "../../../../../utils/colors";
import { useMediaQuery } from "react-responsive";

const ReportClientInfo = ({ data }) => {
  const { t } = useTranslation();

  // MOBILE
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const [workAddress, setWorkAddress] = useState("");

  useEffect(() => {
    if (data && data?.address) {
      const { street, city, zip, country } = data?.address;
      const addressParts = [street, city, zip, country].filter(Boolean);
      setWorkAddress(addressParts.join(", "));
    } else {
      setWorkAddress("-");
    }
  }, [data]);

  const clientArray = [
    ...(data?.clientType === "individual"
      ? [
          {
            label: "ClientType",
            value: (
              <TooltipText
                icon="TbDental"
                label={t("implantForm.clientType")}
                value={t(`implantForm.${data?.clientType}`)}
                iconText
                noTooltip
              />
            ),
          },
          {
            label: "Client",
            value: (
              <TooltipText
                icon="TbCalendar"
                label={t("implantForm.client")}
                value={data?.firstName + " " + data?.lastName}
                iconText
                maxW="200px"
              />
            ),
          },
          {
            label: "Email",
            value: (
              <TooltipText
                icon="TbCalendar"
                label={t("implantForm.email")}
                value={data?.email}
                iconText
                maxW="200px"
              />
            ),
          },
          {
            label: "Phone",
            value: (
              <TooltipText
                icon="TbMapPin"
                label={t("form.phone")}
                value={
                  data?.userId && data?.userId.phone ? data?.userId.phone : "-"
                }
                iconText
                noTooltip
              />
            ),
          },
          {
            label: "Representative",
            value: (
              <TooltipText
                icon="TbMapPin"
                label={t("form.representativeCompany")}
                value={data?.companyName ? data?.companyName : "-"}
                iconText
                maxW="200px"
                noTooltip
              />
            ),
          },
          {
            label: "Address",
            value: (
              <TooltipText
                icon="TbDental"
                label={t("form.whereToSend")}
                value={workAddress}
                iconText
              />
            ),
          },
        ]
      : [
          {
            label: "ClientType",
            value: (
              <TooltipText
                icon="TbDental"
                label={t("implantForm.clientType")}
                value={t(`implantForm.${data?.clientType}`)}
                iconText
                noTooltip
              />
            ),
          },
          {
            label: "CompanyName",
            value: (
              <TooltipText
                icon="TbUsers"
                label={
                  isMobile ? t("form.companyNameShort") : t("form.companyName")
                }
                value={data?.companyName}
                iconText
                maxW="200px"
              />
            ),
          },
          {
            label: "Email",
            value: (
              <TooltipText
                icon="TbCalendar"
                label={t("implantForm.email")}
                value={data?.email}
                iconText
              />
            ),
          },
          {
            label: "CompanyCode",
            value: (
              <TooltipText
                icon="TbMapPin"
                label={t("form.companyCode")}
                value={data?.companyCode}
                iconText
                noTooltip
              />
            ),
          },
          {
            label: "Phone",
            value: (
              <TooltipText
                icon="TbMapPin"
                label={t("form.phone")}
                value={
                  data?.userId && data?.userId.phone ? data?.userId.phone : "-"
                }
                iconText
                noTooltip
              />
            ),
          },
          {
            label: "Representative",
            value: (
              <TooltipText
                icon="TbMapPin"
                label={t("form.representative")}
                value={
                  data?.firstName ? data?.firstName + " " + data?.lastName : "-"
                }
                iconText
                maxW="260px"
                noTooltip={data?.firstName ? false : true}
              />
            ),
          },
          {
            label: "Address",
            value: (
              <TooltipText
                icon="TbDental"
                label={t("form.whereToSend")}
                value={workAddress}
                iconText
                maxW="240px"
              />
            ),
          },
        ]),
  ];

  // console.log("data", data);

  return (
    <Flex w="100%" direction="column">
      <Heading fontSize="md" fontWeight="600" color={textPrimary} m="0 0 1rem">
        {t("implantFailureReports.clientInfoTitle")}
      </Heading>
      <Box>
        {clientArray.map((item, index) => (
          <Flex key={index} alignItems="center">
            {item.value && (
              <Flex p="0.35rem 0" alignItems="center">
                {item.value}
              </Flex>
            )}
          </Flex>
        ))}
      </Box>
    </Flex>
  );
};

export default ReportClientInfo;
