import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  listImplantFailureReports,
  deleteImplantFailureReport,
} from "../../../../actions/reportActions";
import { useNavigate } from "react-router-dom";
import { Flex, Spinner, useToast } from "@chakra-ui/react";
import Panel from "../../../../components/shared/Panel";
import Table from "../../../../components/shared/Table";
import SidePanel from "../../../../components/sidepanel/SidePanel";
import { Box } from "@chakra-ui/react";
import { columns } from "../columns/ImplantFailureReportListColumns"; // Adjust the path according to your file structure
import { useTranslation } from "react-i18next";
import ToastComponent from "../../../../components/partials/ToastComponent";
import { REPORT_IMPLANT_FAILURE_DELETE_RESET } from "../../../../constants/reportConstants";
import CreateImplantFailReport from "../sidepanels/CreateImplantFailReport";
import TableContainerWindowed from "../../../../components/table/TableContainerWindowed";
import TablePaginationNew from "../../../../components/table/TablePaginationNew";

const ImplantFailureReportList = () => {
  const isMobile = window.innerWidth <= 768;
  const isBigScreen = window.innerWidth >= 1820;

  // TRANSLATION
  const { t } = useTranslation();
  const translate = (key) => t(key);

  // NAVIGATION
  const navigate = useNavigate();

  // TOAST
  const toastComponent = ToastComponent();
  const toast = useToast();

  // SEARCH
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const onChangeSearchTerm = (e) => {
    setSearchTerm(e.target.value);
    setPage(1); // ✅ Reset to page 1 when search term changes
  };

  // PAGINATION
  // const [page, setPage] = useState(1);
  // const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(() => {
    return localStorage.getItem("implantReportsPage")
      ? parseInt(localStorage.getItem("implantReportsPage"))
      : 1;
  });
  const [pageSize, setPageSize] = useState(() => {
    return localStorage.getItem("implantReportsPageSize")
      ? parseInt(localStorage.getItem("implantReportsPageSize"))
      : 10;
  });
  const pageSizes = [10, 20, 25];

  // SORTING
  const [sortField, setSortField] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const handleSortChange = useCallback(
    (column) => {
      const newSortOrder =
        sortField === column && sortOrder === "asc" ? "desc" : "asc";
      setSortField(column);
      setSortOrder(newSortOrder);
    },
    [sortField, sortOrder]
  );

  // STATE
  const [newReportId, setNewReportId] = useState("");

  const [reportId, setReportId] = useState("");
  const [searchTitle, setSearchTitle] = useState("");

  const [sidePanelModalOpen, setSidePanelOpen] = useState(false);
  const [editSidePanelOpen, setEditSidePanelOpen] = useState(false);

  // REDUX
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const reportImplantFailureList = useSelector(
    (state) => state.reportImplantFailureList
  );
  const { loading, reports, error, pages } = reportImplantFailureList;

  const reportImplantFailureCreate = useSelector(
    (state) => state.reportImplantFailureCreate
  );

  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    trackingNumber,
  } = reportImplantFailureCreate;

  const reportImplantFailureDelete = useSelector(
    (state) => state.reportImplantFailureDelete
  );
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = reportImplantFailureDelete;

  // HANDLERS

  const deleteHandler = (reportId) => {
    console.log("click");
    dispatch(deleteImplantFailureReport(reportId));

    dispatch({ type: REPORT_IMPLANT_FAILURE_DELETE_RESET });
    if (reports.length === 1) {
      // setPage(page - 1);
    }
  };

  // USE EFFECT
  // Save state changes to localStorage
  useEffect(() => {
    localStorage.setItem("implantReportsPage", page);
    localStorage.setItem("implantReportsPageSize", pageSize);
  }, [page, pageSize]);

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      navigate("/login");
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    dispatch(
      listImplantFailureReports(
        searchTerm,
        page,
        pageSize,
        sortField,
        sortOrder
      )
    );
  }, [
    dispatch,
    searchTerm,
    page,
    pageSize,
    sortField,
    sortOrder,
    trackingNumber,
    successCreate,
    successDelete,
  ]);

  // TOASTS
  useEffect(() => {
    if (successCreate) {
      setTimeout(() => setNewReportId(""), 9000);
    }

    if (errorCreate) {
      toastComponent.showToast(
        t("implantFailureReports.toastCreateError"),

        "error",
        3000
      );
    }
  }, [successCreate, errorCreate]);

  useEffect(() => {
    if (successDelete) {
      toast({
        title: t("implantFailureReports.reportDeleted"),
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      dispatch({ type: REPORT_IMPLANT_FAILURE_DELETE_RESET });
    } else if (errorDelete) {
      toast({
        title: t("implantFailureReports.error"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [successDelete, errorDelete]);

  const openSidePanelModal = () => {
    setSidePanelOpen(true);
    document.body.classList.add("no-scroll");
  };

  const noItemsHandler = () => {
    openSidePanelModal();
  };

  const closeSidePanelModal = () => {
    setSidePanelOpen(false);
    setEditSidePanelOpen(false);
    setReportId("");
    document.body.classList.remove("no-scroll");
  };
  const closeEditSidePanelModal = () => {
    setEditSidePanelOpen(false);
    setSidePanelOpen(false);
    setReportId("");
    document.body.classList.remove("no-scroll");
  };

  const handleRowClick = (value) => {
    navigate(`/admin/reports/implant-failure-reports/${value}`);
  };

  console.log("reports", reports);
  console.log("pages", pages);
  console.log("page", page);
  console.log("pageSize", pageSize);

  console.log("searchTerm", searchTerm);
  // console.log("searchTitle", searchTitle);

  return (
    <>
      {/* HELMET  */}
      <Helmet>
        <title>{t("pageTitle.adminReportsImplantFailureReports")}</title>
        <meta
          name="description"
          content={t("pageDescription.adminReportsImplantFailureReports")}
        />
      </Helmet>

      <SidePanel
        open={sidePanelModalOpen}
        close={closeSidePanelModal}
        title={t("implantForm.createImplantFailReport")}
        noBorder={true}
      >
        <CreateImplantFailReport
          close={closeSidePanelModal}
          setNewReportId={setNewReportId}
        />
      </SidePanel>

      <SidePanel
        open={editSidePanelOpen}
        close={closeEditSidePanelModal}
        title={t("speaker.editSpeaker")}
        noBorder={false}
      >
        {/* <EditSpeaker speakerId={speakerId} /> */}
      </SidePanel>

      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <Box px={8}>
              <Table.Top
                title={t("implantFailureReports.title")}
                onChange={onChangeSearchTerm}
                placeholder={t("implantFailureReports.search")}
                searchName={searchTerm}
                buttonLabel={t("implantFailureReports.createReport")}
                buttonHandler={openSidePanelModal}
                buttonDisabled={false}
                isFixedTitle={false}
              />
            </Box>

            {!loading ? (
              <>
                <TableContainerWindowed
                  loading={loading}
                  data={reports}
                  columns={columns(
                    translate,
                    handleSortChange,
                    deleteHandler,
                    isBigScreen
                  )}
                  noItemsTitle={t("record.noRecordsTitle")}
                  noItemsDescription={t("record.noRecordsDescription")}
                  noItemsButtonHandler={noItemsHandler}
                  noItemsButtonLabel={t("record.noRecordsButton")}
                  noItemsButtonDisabled={false}
                  pageSize={pageSize}
                  sortField={sortField}
                  sortOrder={sortOrder}
                  handleSortChange={handleSortChange}
                  headerHeight="36"
                  headerFontSize="10px"
                  sortIconSize="12px"
                  onRowClick={handleRowClick}
                />
                <Flex p="1rem 0" w="100%">
                  <TablePaginationNew
                    loading={loading}
                    page={page}
                    pages={pages}
                    pageSize={pageSize}
                    pageSizes={pageSizes}
                    navigate={navigate}
                    setPage={setPage}
                    setPageSize={setPageSize}
                    totalCount={reports?.length || 0}
                  />
                </Flex>
              </>
            ) : (
              <Flex w="100%" justify="center" align="center" p="3rem">
                <Spinner size="lg" color="blue.500" />
              </Flex>
            )}

            {/* <Table.Container
              columns={columns(translate, handleSortChange, deleteHandler)}
              data={reports && reports}
              loading={loading}
              error={error}
              navigate={navigate}
              navigateTo="/admin/reports/implant-failure-reports"
              noItemsButtonHandler={openSidePanelModal}
              noItemsTitle={t("noItems.noImplantFailureReports")}
              noItemsDescription={t("noItems.noImplantFailureReportsText")}
              noItemsButtonLabel={t("noItems.noImplantFailureReportsButton")}
              onRowClick={handleRowClick}
              newReportId={newReportId}
              sortField={sortField}
              sortOrder={sortOrder}
            />
            <Table.Pagination
              page={page}
              pages={pages}
              nextPage={nextPage}
              previousPage={previousPage}
              gotoPage={gotoPage}
              handlePageSizeChange={handlePageSizeChange}
              pageSize={pageSize}
              pageSizes={pageSizes}
              navigate={navigate}
            /> */}
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default ImplantFailureReportList;
