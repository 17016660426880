import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { registerNewEmployee } from "../../../../actions/userActions";
import { backgroundLight } from "../../../../utils/colors";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Flex,
  Image,
  Tooltip,
  HStack,
  Text,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react";
import {
  primary,
  white,
  newBorder,
  textSecondary,
} from "../../../../utils/colors";
import Select from "react-select";
import CitySelect from "../../../../components/select/CitySelect";
import ProfessionSelect from "../../../../components/select/ProfessionSelect";
import TabsComponent from "../../../../components/tabs/TabsComponent";
import ImageUpload from "../../../../components/input/ImageUpload";
import MultiCourseSelect from "../../../../components/select/MultiCourseSelect";
import InputField from "../../../../components/input/InputField";
import { useTranslation } from "react-i18next";
import FormControlDefault from "../../../../components/input/FormControlDefault";
import FormControlDatePicker from "../../../../components/input/FormControlDatePicker";
import FormControlPhone from "../../../../components/input/FormControlPhone";
import FormControlAddress from "../../../../components/input/FormControlAddress";
import FormControlCountry from "../../../../components/input/FormControlCountry";
import FormControlProfession from "../../../../components/input/FormControlProfession";
import FormControlClient from "../../../../components/input/FormControlClient";
import { PasswordField } from "../../../../components/partials/PasswordField";
import { USER_REGISTER_RESET } from "../../../../constants/userConstants";
import ToastComponent from "../../../../components/partials/ToastComponent";

import { shade } from "polished";

const CreateEmployee = ({ closeSidePanelModal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toastComponent = ToastComponent();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [street, setStreet] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");

  // TOUCHED
  const [firstNameTouched, setFirstNameTouched] = useState(false);
  const [lastNameTouched, setLastNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);

  // EMAIL VALIDATION
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // ERRORS
  const isFirstNameError = firstName === "";
  const isLastNameError = lastName === "";
  const isEmailError = email === "";
  const isPasswordError = password === "";

  // VALIDATION
  const isFirstNameValid = firstName.length >= 1;
  const isLastNameValid = lastName.length >= 1;
  const isEmailValid = isValidEmail(email);
  const isPasswordValid = password.length >= 1;

  // Determine if the Continue button should be disabled
  const isContinueDisabled =
    !isFirstNameValid || !isLastNameValid || !isEmailValid || !isPasswordValid;

  // SUBMIT

  const submitHandler = async (event) => {
    event.preventDefault();

    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);

    try {
      // Use startDateTime and endDateTime in your createCourse action as needed
      await dispatch(
        registerNewEmployee(
          firstName,
          lastName,
          email,
          password,
          birthDate,
          phone,
          street,
          zip,
          city,
          state,
          country
        )
      );
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }

    // toastComponent.showToast(t("toasts.userCreate"), "success", 3000);
    dispatch({ type: USER_REGISTER_RESET }); // Resetting right after showing the toast
    closeSidePanelModal();
    document.body.classList.remove("no-scroll");
  };

  // HANDLERS

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleAddressSelect = ({ street, city, zip, country, state }) => {
    setStreet(street);
    setCity(city);
    setZip(zip);
    setCountry(country);
    setState(state);
  };

  // console.log("firstName", firstName);
  // console.log("lastName", lastName);
  // console.log("email", email);
  // console.log("password", password);
  // console.log("birthDate", birthDate);
  // console.log("phone", phone);
  // console.log("street", street);
  // console.log("zip", zip);
  // console.log("city", city);
  // console.log("state", state);
  // console.log("country", country);

  return (
    <form onSubmit={submitHandler}>
      <HStack gap={4} w="100%">
        <FormControlDefault
          type="text"
          isRequired
          label={t("form.firstName")}
          value={firstName}
          placeholder={t("placeholder.firstName")}
          onChange={(e) => {
            setFirstName(e.target.value);
            setFirstNameTouched(true);
          }}
          onBlur={() => setFirstNameTouched(true)}
          isTouched={firstNameTouched}
          isError={isFirstNameError}
          w="50%"
          errorMessage={t("formError.firstNameRequired")}
        />

        <FormControlDefault
          type="text"
          isRequired
          label={t("form.lastName")}
          value={lastName}
          placeholder={t("placeholder.lastName")}
          onChange={(e) => {
            setLastName(e.target.value);
            setLastNameTouched(true);
          }}
          onBlur={() => setLastNameTouched(true)}
          isTouched={lastNameTouched}
          isError={isLastNameError}
          w="50%"
          errorMessage={t("formError.lastNameRequired")}
        />
      </HStack>

      <FormControlDefault
        type="email"
        label={t("form.email")}
        isRequired
        value={email}
        placeholder={t("placeholder.email")}
        onChange={(e) => {
          setEmail(e.target.value);
          setEmailTouched(true);
        }}
        onBlur={() => setEmailTouched(true)}
        isTouched={emailTouched}
        isError={!isEmailValid}
        w="100%"
        errorMessage={t("formError.emailInvalid")}
      />

      <PasswordField
        value={password}
        onChange={handlePasswordChange}
        size="lg"
        fontSize="0.938rem"
        fontWeight="500"
        border={`1px solid ${newBorder}`}
        borderRadius="0.75rem"
        placeholder={t("placeholder.password")}
      />

      <HStack gap={4} w="100%" mt={4}>
        <FormControlDatePicker
          type="date"
          isRequired={false}
          label={t("form.birthDate")}
          placeholder={t("placeholder.birthDate")}
          onChange={(newValue) => {
            setBirthDate(newValue);
          }}
          onOpen={() => {}}
          w="50%"
          errorMessage={t("formError.birthDateRequired")}
        />

        <FormControlPhone
          label={t("form.phone")}
          value={phone}
          onChange={(newPhone) => {
            setPhone(newPhone);
          }}
          errorMessage={t("formError.phoneRequired")}
          w="50%"
          defaultCountry="lt"
        />
      </HStack>

      <HStack gap={4} w="100%">
        <FormControlAddress
          label={t("form.address")}
          onAddressSelect={handleAddressSelect}
          isRequired={false}
          errorMessage={t("formError.addressRequired")}
          w="100%"
        />

        <FormControlDefault
          type="text"
          isRequired={false}
          label={t("form.zip")}
          value={zip}
          placeholder={t("placeholder.zip")}
          onChange={(e) => {
            setZip(e.target.value);
          }}
          w="40%"
          errorMessage={t("formError.zipRequired")}
        />
      </HStack>

      <HStack gap={4} w="100%">
        <FormControlDefault
          type="text"
          isRequired={false}
          label={t("form.city")}
          value={city}
          placeholder={t("placeholder.city")}
          onChange={(e) => {
            setCity(e.target.value);
          }}
          w="50%"
          errorMessage={t("formError.cityRequired")}
        />

        <FormControlCountry
          label={t("form.country")}
          value={country}
          onChange={(selectedOption) => {
            setCountry(selectedOption ? selectedOption.value : "");
          }}
          isRequired={false}
          w="50%"
          errorMessage={t("formError.countryRequired")}
        />
      </HStack>

      <Flex
        mt={4}
        direction="column"
        borderTop={`1px solid ${newBorder}`}
        p="2rem 0 0"
      >
        <Flex justify="flex-end">
          <Tooltip
            bg={white}
            borderRadius="1rem"
            p="1rem"
            label={
              <Flex
                direction="column"
                p="0rem"
                justify="center"
                textAlign="center"
              >
                {isFirstNameError && (
                  <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                    * {t("formError.firstNameRequired")}
                  </Text>
                )}
                {isLastNameError && (
                  <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                    * {t("formError.lastNameRequired")}
                  </Text>
                )}
                {!isEmailValid && (
                  <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                    * {t("formError.emailInvalid")}
                  </Text>
                )}
                {isPasswordError && (
                  <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                    * {t("formError.passwordRequired")}
                  </Text>
                )}
              </Flex>
            }
            isDisabled={!isContinueDisabled}
            hasArrow
            placement="top"
            fontSize="md"
          >
            <Button
              minWidth="10rem"
              type="submit"
              size="lg"
              bg={primary}
              color={white}
              fontSize="md"
              borderRadius="10rem"
              isDisabled={isContinueDisabled}
              onClick={submitHandler}
              _hover={{
                bg: shade(0.1, primary),
              }}
              ml={4}
            >
              {t("user.createUser")}
            </Button>
          </Tooltip>

          <Flex align="center"></Flex>
        </Flex>
      </Flex>
    </form>
  );
};

export default CreateEmployee;
