import React from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Flex,
  Box,
  Text,
} from "@chakra-ui/react";
import IconButtonComponent from "../buttons/IconButtonComponent";
import { newBorder, textPrimary } from "../../utils/colors";
import { FaXmark } from "react-icons/fa6";

const ImageViewer = ({ isOpen, onClose, imageUrl, title }) => {
  if (!imageUrl) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent borderRadius="2rem">
        <Flex
          w="100%"
          align="center"
          justify="space-between"
          p="1.5rem 2rem"
          borderBottom={`1px solid ${newBorder}`}
        >
          <Text fontSize="xl" fontWeight="600" color={textPrimary}>
            {title}
          </Text>
          <IconButtonComponent
            noTooltip
            buttonIcon={FaXmark}
            onClick={() => onClose()}
          />
        </Flex>

        <ModalBody>
          <Flex justify="center" align="center" minH="60vh" p="1.5rem 0">
            <TransformWrapper>
              <TransformComponent>
                <Box
                  as="img"
                  src={imageUrl}
                  alt={title}
                  maxW="100%"
                  maxH="80vh"
                  objectFit="contain" // Ensures the image scales correctly
                  borderRadius="1rem"
                  display="block"
                  overflow="hidden"
                />
              </TransformComponent>
            </TransformWrapper>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ImageViewer;
