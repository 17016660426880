import React, { useState, useEffect, useRef } from "react";
import { HStack, Button, Flex, Tooltip, Box } from "@chakra-ui/react";
import {
  white,
  primary,
  buttonRed,
  buttonRedText,
} from "../../../../../utils/colors";
// import FormControlImageUpload from "../components/FormControlImageUpload";
import { useTranslation } from "react-i18next";
import { shade } from "polished";
import FormControlDefault from "../../../../../components/input/FormControlDefault";
import FormControlPatientRisks from "../components/FormControlPatientRisks";
import FormControlPatientHygiene from "../components/FormControlPatientHygiene";
import FormControlGender from "../../../../../components/input/FormControlGender";

const Step3 = ({
  setActiveStep,
  setIsStep3Touched,
  isValid,
  patientCode,
  setPatientCode,
  patientAge,
  setPatientAge,
  patientGender,
  setPatientGender,
  patientRisks,
  setPatientRisks,
  patientHygiene,
  setPatientHygiene,
}) => {
  // GENERAL
  const { t } = useTranslation();

  // REF for first input
  const firstInputRef = useRef(null);

  // Focus the first input when Step 3 is opened
  useEffect(() => {
    if (firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // ✅ Always scroll to the top
  }, []);

  // LOGS

  // RENDER
  return (
    <Flex w="100%" maxW="600px" direction="column">
      <Flex w="100%" direction="column">
        <Flex
          w="100%"
          align="center"
          justify="center"
          direction="column"
          pb="0.5rem"
        >
          <HStack gap={4} w="100%" mt={0}>
            <FormControlDefault
              type="number"
              isRequired
              label={t("form.patientAge")}
              value={patientAge}
              placeholder={t("placeholder.patientAge")}
              onChange={(e) => {
                setPatientAge(e.target.value);
              }}
              w="50%"
              ref={firstInputRef}
              // isDisabled={
              //   userInfo && userInfo.firstName && userInfo.firstName
              //     ? true
              //     : true
              // }
              // rightElement={userInfo ? iconElement : null}
              // isTooltip={userInfo}
              // tooltipLabel={tooltipLabel}
              // noDisabledOpacity
            />

            <FormControlGender
              label={t("form.patientGender")}
              value={patientGender}
              placeholder={t("placeholder.patientGender")}
              onChange={(selectedOption) => {
                setPatientGender(selectedOption ? selectedOption.value : "");
              }}
              isRequired
              errorMessage={t("formError.patientGenderRequired")}
              w="50%"
            />
          </HStack>

          <HStack gap={4} w="100%">
            <FormControlPatientRisks
              isRequired
              setValue={setPatientRisks}
              label={t("form.patientRisks")}
              value={patientRisks}
              // onChange={handleFixtureChange}
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setPatientRisks(selectedOption.value);
                } else {
                  setPatientRisks(null); // or any default value you prefer
                }
              }}
              placeholder={t("placeholder.patientRisks")}
              w="50%"
              errorMessage={t("formError.patientRisksRequired")}
            />

            <FormControlPatientHygiene
              isRequired
              setValue={setPatientHygiene}
              label={t("form.patientHygiene")}
              value={patientHygiene}
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setPatientHygiene(selectedOption.value);
                } else {
                  setPatientHygiene(null); // or any default value you prefer
                }
              }}
              placeholder={t("placeholder.patientHygiene")}
              w="50%"
              errorMessage={t("formError.patientHygieneRequired")}
            />
          </HStack>

          <Box w="100%">
            <FormControlDefault
              type="text"
              label={t("form.patientCode")}
              value={patientCode}
              placeholder={t("placeholder.patientCode")}
              onChange={(e) => {
                setPatientCode(e.target.value);
              }}
              w="100%"
              // isDisabled={
              //   userInfo && userInfo.firstName && userInfo.firstName
              //     ? true
              //     : true
              // }
              // rightElement={userInfo ? iconElement : null}
              // isTooltip={userInfo}
              // tooltipLabel={tooltipLabel}
              // noDisabledOpacity
            />
          </Box>
        </Flex>
      </Flex>

      <Flex w="100%" justify="flex-end" m="1.5rem 0 0">
        <Button
          size="lg"
          fontSize="md"
          bg={primary}
          color={white}
          fontWeight="500"
          borderRadius="10rem"
          minW="8rem"
          onClick={() => {
            setActiveStep(4);
            setIsStep3Touched(true);
          }}
          _hover={{
            bg: shade(0.3, primary),
          }}
          isDisabled={!isValid}
          autoFocus={false}
        >
          {t("common.continue")}
        </Button>
      </Flex>
    </Flex>
  );
};

export default Step3;
