import React, { useState, useRef, useEffect, forwardRef } from "react";
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Text,
  Flex,
  Box,
} from "@chakra-ui/react";
import {
  textPrimary,
  textSecondary,
  buttonBlue,
  buttonBlueText,
  buttonBlueDark,
  buttonGreen,
  buttonGreenText,
  buttonGreenDark,
  buttonRed,
  buttonRedText,
  buttonRedDark,
  buttonCyan,
  buttonCyanText,
  buttonCyanDark,
  buttonPurple,
  buttonPurpleText,
  buttonPurpleDark,
  white,
  backgroundLight,
  newBorder,
  greenVivid,
  buttonLightPurple,
  buttonLightPurpleText,
  buttonLightPurpleDark,
  buttonRedNew,
  buttonYellow,
  buttonYellowDark,
  buttonYellowText,
  primary,
} from "../../utils/colors";
import { useTranslation } from "react-i18next";
import {
  FaRegCircle,
  FaCircleHalfStroke,
  FaCircleXmark,
  FaClock,
  FaCircleCheck,
  FaCircleExclamation,
  FaCaretDown,
  FaRegCircleCheck,
  FaRegCircleDot,
  FaAngleDown,
  FaUser,
} from "react-icons/fa6";
import { shade, tint } from "polished";
import { TbUsb, TbUser, TbWallet } from "react-icons/tb";

const StatusButtonTiny = forwardRef(
  (
    {
      currentStatus,
      onUpdateStatus,
      options,
      isUnclickable,
      isBig,
      isSmall,
      isUserCancelled,
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const btnRef = useRef();
    const { t } = useTranslation();
    const translate = (key) => t(key);

    const handleStatusChange = (status) => {
      onUpdateStatus(status);
      console.log("handleStatusChange", status);
      setIsOpen(false);
    };

    const handleClickOutside = (event) => {
      if (btnRef.current && !btnRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    if (options === "course") {
      options = [
        {
          value: "draft",
          label: translate("status.draft"),
          icon: FaRegCircle,
          bg: backgroundLight,
          bgDark: textSecondary,
          color: textSecondary,
          borderColor: newBorder,
          bgHover: "#ddd",
        },
        {
          value: "upcoming",
          label: translate("status.upcoming"),
          icon: FaClock,
          bg: buttonLightPurple,
          bgDark: buttonLightPurpleDark,
          color: textPrimary,
          borderColor: buttonBlue,
          bgHover: "#b6cce1",
        },
        {
          value: "cancelled",
          label: translate("status.cancelled"),
          icon: FaCircleXmark,
          bg: buttonRed,
          bgDark: buttonRedDark,
          color: buttonRedText,
          borderColor: buttonRed,
          bgHover: "#dda3a3",
        },
        {
          value: "completed",
          label: translate("status.completed"),
          icon: FaRegCircleCheck,
          bg: greenVivid,
          bgDark: greenVivid,
          color: textPrimary,
          borderColor: greenVivid,
          bgHover: greenVivid,
        },
        {
          value: "full",
          label: translate("status.full"),
          icon: FaRegCircleDot,
          bg: textPrimary,
          bgDark: textPrimary,
          color: white,
          borderColor: textPrimary,
          bgHover: textPrimary,
        },
      ];
    } else if (options === "active") {
      options = [
        {
          value: "inactive",
          label: translate("status.inactive"),
          icon: FaRegCircle,
          bg: backgroundLight,
          bgDark: textSecondary,
          color: textSecondary,
          borderColor: newBorder,
          bgHover: "#ddd",
        },

        {
          value: "active",
          label: translate("status.active"),
          icon: FaCircleCheck,
          bg: buttonGreen,
          bgDark: buttonGreenDark,
          color: buttonGreenText,
          borderColor: buttonGreen,
          bgHover: "#9ebfc2",
        },

        {
          value: "unverified",
          label: translate("status.unverified"),
          icon: FaRegCircle,
          bg: backgroundLight,
          bgDark: textSecondary,
          color: textSecondary,
          borderColor: newBorder,
          bgHover: "#ddd",
        },
      ];
    } else if (options === "paid") {
      options = [
        {
          value: "paid",
          label: translate("status.paid"),
          icon: FaCircleCheck,
          bg: buttonGreen,
          bgDark: buttonGreenDark,
          color: buttonGreenText,
          borderColor: buttonGreen,
          bgHover: "#9ebfc2",
        },

        {
          value: "unpaid",
          label: translate("status.unpaid"),
          icon: FaCircleXmark,
          bg: buttonRed,
          bgDark: buttonRedDark,
          color: buttonRedText,
          borderColor: buttonRed,
          bgHover: "#dda3a3",
        },
      ];
    } else if (options === "attendees") {
      options = [
        {
          value: "registered",
          label: translate("status.registered"),
          icon: FaRegCircleDot,
          bg: buttonBlue,
          bgDark: buttonBlue,
          color: textPrimary,
          borderColor: buttonBlue,
          bgHover: "#ddd",
        },
        {
          value: "confirmed",
          label: translate("status.confirmed"),
          icon: FaCircleCheck,
          bg: buttonGreen,
          bgDark: buttonGreenDark,
          color: buttonGreenText,
          borderColor: buttonGreen,
          bgHover: "#9ebfc2",
        },
        {
          value: "attended",
          label: translate("status.attended"),
          icon: FaRegCircleCheck,
          bg: greenVivid,
          bgDark: greenVivid,
          color: textPrimary,
          borderColor: greenVivid,
          bgHover: greenVivid,
        },

        {
          value: "cancelled",
          label: translate("status.cancelled"),
          icon: FaCircleXmark,
          bg: buttonRed,
          bgDark: buttonRedDark,
          color: buttonRedText,
          borderColor: buttonRed,
          bgHover: "#dda3a3",
        },

        {
          value: "not_attended",
          label: translate("status.notAttended"),
          icon: FaCircleExclamation,
          bg: buttonRedNew,
          bgDark: buttonPurpleDark,
          color: white,
          borderColor: buttonPurple,
          bgHover: "#9ebfc2",
        },
        {
          value: "unverified",
          label: translate("status.unverified"),
          icon: FaRegCircle,
          bg: backgroundLight,
          bgDark: textSecondary,
          color: textSecondary,
          borderColor: newBorder,
          bgHover: "#ddd",
        },
      ];
    } else if (options === "report") {
      options = [
        {
          value: "pending",
          label: translate("status.pending"),
          icon: FaClock,
          bg: buttonYellow,
          bgDark: buttonYellowDark,
          color: buttonYellowText,
          borderColor: newBorder,
          bgHover: "#ddd",
        },
        {
          value: "approved",
          label: translate("status.approved"),
          icon: FaCircleCheck,
          bg: buttonGreen,
          bgDark: buttonGreenDark,
          color: buttonGreenText,
          borderColor: buttonGreen,
          bgHover: "#9ebfc2",
        },
        {
          value: "rejected",
          label: translate("status.rejected"),
          icon: FaCircleExclamation,
          bg: buttonRedNew,
          bgDark: buttonPurpleDark,
          color: white,
          borderColor: buttonPurple,
          bgHover: "#9ebfc2",
        },

        {
          value: "cancelled",
          label: translate("status.cancelledAlt"),
          icon: FaCircleXmark,
          bg: buttonRed,
          bgDark: buttonRedDark,
          color: buttonRedText,
          borderColor: buttonRed,
          bgHover: "#dda3a3",
        },
        {
          value: "closed",
          label: translate("status.closed"),
          icon: FaRegCircleCheck,
          bg: greenVivid,
          bgDark: greenVivid,
          color: textPrimary,
          borderColor: greenVivid,
          bgHover: greenVivid,
        },
      ];
    } else if (options === "record") {
      options = [
        {
          value: "draft",
          label: translate("status.recordDraft"),
          icon: FaRegCircle,
          bg: backgroundLight,
          bgDark: textSecondary,
          color: textSecondary,
          borderColor: newBorder,
          bgHover: "#ddd",
        },
        {
          value: "sent",
          label: translate("status.recordSent"),
          icon: FaRegCircleCheck,
          bg: greenVivid,
          bgDark: greenVivid,
          color: textPrimary,
          borderColor: greenVivid,
          bgHover: greenVivid,
        },
      ];
    } else {
      // You can leave this part as it is
    }

    const currentStatusOption = options.find(
      (option) => option.value === currentStatus
    );

    return (
      <Box ref={ref} p={0} borderRadius="0.75rem">
        <Menu isLazy>
          <MenuButton
            size="xs"
            as={Button}
            bg={currentStatusOption ? currentStatusOption.bg : white}
            color={
              currentStatusOption ? currentStatusOption.color : textSecondary
            }
            p="0.5rem 1rem"
            display="flex"
            alignItems="center"
            justifyContent="center"
            _hover={{
              bg: currentStatusOption && shade(0.2, currentStatusOption.bg),
              color: isBig
                ? white
                : currentStatusOption && currentStatusOption.color,
              cursor: isUnclickable ? "default" : "pointer",
            }}
            // minWidth={[
            //   isBig ? "140px" : isSmall ? "initial" : "140px",
            //   isBig ? "140px" : isSmall ? "initial" : "initial",
            //   isBig ? "140px" : isSmall ? "110px" : "150px",
            // ]}
            // maxWidth={[
            //   isBig ? "140px" : isSmall ? "initial" : "initial",
            //   isBig ? "140px" : isSmall ? "initial" : "initial",
            //   isBig ? "140px" : isSmall ? "110px" : "150px",
            // ]}

            borderRadius="0.75rem"
          >
            {currentStatusOption ? (
              <>
                <Flex gap={0} align="center" justify="center">
                  <Flex align="center">
                    {isUserCancelled ? (
                      <>
                        <Icon
                          as={currentStatusOption.icon}
                          color={currentStatusOption.color}
                          mr={[isSmall ? "0.25rem" : "0.5rem"]}
                          h={isSmall ? "0.5rem" : "0.75rem"}
                          w={isSmall ? "0.5rem" : "0.75rem"}
                        />
                        <Icon
                          as={FaUser}
                          color={currentStatusOption.color}
                          mr={[isSmall ? "0.25rem" : "0.5rem"]}
                          h={isSmall ? "0.5rem" : "0.75rem"}
                          w={isSmall ? "0.5rem" : "0.75rem"}
                        />
                      </>
                    ) : (
                      <Icon
                        as={currentStatusOption.icon}
                        color={currentStatusOption.color}
                        mr={[isSmall ? "0.25rem" : "0.5rem"]}
                        h={isSmall ? "0.5rem" : "0.75rem"}
                        w={isSmall ? "0.5rem" : "0.75rem"}
                      />
                    )}
                    <Text
                      fontSize={[
                        isBig ? "0.875rem" : "sm",
                        isBig ? "0.875rem" : "sm",
                        isBig ? "0.875rem" : "sm",
                      ]}
                      fontWeight={isBig ? "500" : "500"}
                      lineHeight="1"
                      textTransform="capitalize"
                    >
                      {currentStatusOption.label}
                    </Text>
                  </Flex>

                  {isUnclickable ? null : (
                    <Icon
                      as={FaCaretDown}
                      ml="0.25rem"
                      h="0.75rem"
                      w="0.75rem"
                    />
                  )}
                </Flex>
              </>
            ) : (
              "Unknown"
            )}
          </MenuButton>

          {isUnclickable ? null : (
            <MenuList p={0} m={0} borderRadius="1rem" overflow="hidden">
              {options.map((option) => (
                <MenuItem
                  key={option.value}
                  onClick={() => handleStatusChange(option.value)}
                  color={option.color}
                  py={4}
                  px={4}
                  borderBottom={`1px solid ${newBorder}`}
                >
                  <Flex
                    w="1.125rem"
                    h="1.125rem"
                    mr="0.5rem"
                    borderRadius="10rem"
                    bg={option.bg}
                    justify="center"
                    align="center"
                    textAlign="center"
                  >
                    <Icon
                      as={option.icon}
                      color={option.color}
                      h="0.65rem"
                      w="0.6rem"
                      style={{
                        pointerEvents: "none",
                      }}
                    />
                  </Flex>
                  <Text
                    fontSize={["sm", "sm", "0.875rem"]}
                    fontWeight="500"
                    lineHeight="1"
                    style={{
                      pointerEvents: "none",
                    }}
                    color={textSecondary}
                  >
                    {option.label}
                  </Text>
                </MenuItem>
              ))}
            </MenuList>
          )}
        </Menu>
      </Box>
    );
  }
);

export default StatusButtonTiny;
