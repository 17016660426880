import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  Flex,
  Stack,
  Text,
  Heading,
  Icon,
  Tabs,
  TabList,
  Tab,
  useToast,
  Button,
} from "@chakra-ui/react";
import Header from "../../../landing/layout/Header";
import { useTranslation } from "react-i18next";
import {
  backgroundLight,
  white,
  textPrimary,
  textSecondary,
  newBorder,
  primary,
  buttonRedText,
} from "../../../../utils/colors";
import Step0 from "./partials/Step0";
import Step1 from "./partials/Step1";
import Step2 from "./partials/Step2";
import Step3 from "./partials/Step3";
import Step4 from "./partials/Step4";
import StepSuccess from "./partials/StepSuccess";
import { FaUserCircle } from "react-icons/fa";
import {
  TbDental,
  TbDentalBroken,
  TbRadioactiveFilled,
  TbArrowsLeftRight,
  TbUserCircle,
} from "react-icons/tb";
import { FaCheck } from "react-icons/fa6";
import { createImplantFailureReport } from "../../../../actions/reportActions";
import { TbMessageCircleQuestion } from "react-icons/tb";
import { REPORT_IMPLANT_FAILURE_CREATE_RESET } from "../../../../constants/reportConstants";
import Faq from "../../../general/partials/Faq";
import Footer from "../../../landing/layout/Footer";
// import AccountTabs from "../../account/partials/AccountTabs";
import { useMediaQuery } from "react-responsive";
import { shade } from "polished";
import { useNavigate } from "react-router-dom";

const ImplantFailForm = () => {
  // GENERAL
  const { t } = useTranslation();
  const toast = useToast();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const navigate = useNavigate();

  // Adjusting tabs based on the client type
  let tabs = [
    {
      title: t("implantForm.mainHeading"),
      disabled: false,
      path: "/account",
    },
  ];

  //STATE
  const fixtureOptions = [
    { value: "mini", label: "Mini", shortCode: "MN", core: false },
    { value: "st", label: "ST", shortCode: "ST", core: false },
    { value: "anyone", label: "AnyOne", shortCode: "AO", core: true },
    { value: "anyridge", label: "AnyRidge", shortCode: "AR", core: true },
    {
      value: "bluediamond",
      label: "Blue Diamond",
      shortCode: "BD",
      core: true,
    },
    { value: "ezplus", label: "EZ Plus", shortCode: "EZ", core: false },
    { value: "rescue", label: "Rescue", shortCode: "RS", core: false },
  ];

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [userId, setUserId] = useState("");
  const [clientType, setClientType] = useState("individual");
  const [email, setEmail] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [companyName, setCompanyName] = useState(null);
  const [companyCode, setCompanyCode] = useState(null);

  const [fixture, setFixture] = useState(null);
  const [core, setCore] = useState(null);
  const [diameter, setDiameter] = useState("");
  const [length, setLength] = useState("");
  const [refCode, setRefCode] = useState("");
  const [lotNumber, setLotNumber] = useState("");

  const [tooth, setTooth] = useState(null);
  const [surgeonFirstName, setSurgeonFirstName] = useState("");
  const [surgeonLastName, setSurgeonLastName] = useState("");
  const [surgeryDate, setSurgeryDate] = useState("");
  const [failureDate, setFailureDate] = useState("");

  const [beforeXrayImage, setBeforeXrayImage] = useState("");
  const [afterXrayImage, setAfterXrayImage] = useState("");

  const [newFixture, setNewFixture] = useState(null);
  const [newCore, setNewCore] = useState(null);
  const [newDiameter, setNewDiameter] = useState("");
  const [newLength, setNewLength] = useState("");
  const [newRefCode, setNewRefCode] = useState("");

  const [reason, setReason] = useState(null);
  const [reasonText, setReasonText] = useState("");

  const [author, setAuthor] = useState(""); // "client" or "employee
  const [authorType, setAuthorType] = useState("client");

  const [userTrackingNumber, setUserTrackingNumber] = useState("");

  const [address, setAddress] = useState({
    street: "",
    city: "",
    country: "",
    zip: "",
    state: "",
    display: "",
  });

  const [displayAddress, setDisplayAddress] = useState("");
  const [manualAddressVisible, setManualAddressVisible] = useState(false);

  // NEW STEP PATIENT INFORMATION STATE
  const [patientCode, setPatientCode] = useState("");
  const [patientAge, setPatientAge] = useState("");
  const [patientGender, setPatientGender] = useState("");
  const [patientRisks, setPatientRisks] = useState("");
  const [patientHygiene, setPatientHygiene] = useState("");

  const [notes, setNotes] = useState("");

  // REDUX
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const reportImplantFailureCreate = useSelector(
    (state) => state.reportImplantFailureCreate
  );

  const {
    loading: loadingCreate,
    error: errorCreate,
    success,
    trackingNumber,
  } = reportImplantFailureCreate;

  // HANDLERS
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      createImplantFailureReport(
        userId,
        clientType,
        email,
        firstName,
        lastName,
        companyName,
        companyCode,
        fixture,
        core,
        diameter,
        length,
        refCode,
        lotNumber,
        tooth,
        surgeonFirstName,
        surgeonLastName,
        surgeryDate,
        failureDate,
        beforeXrayImage,
        afterXrayImage,
        newFixture,
        newCore,
        newDiameter,
        newLength,
        newRefCode,
        reason,
        reasonText,
        author,
        authorType,
        address,
        patientCode,
        patientAge,
        patientGender,
        patientRisks,
        patientHygiene,
        notes
      )
    );
  };

  const handleFixtureChange = (value) => {
    setFixture(value);
    setCore(null);
    setDiameter("");
    setLength("");
    setRefCode("");
    setLotNumber();
  };

  const handleNewFixtureChange = (value) => {
    setNewFixture(value);
    setNewCore(null);
    setNewDiameter("");
    setNewLength("");
    setNewRefCode("");
  };

  // reset everything and start new form fresh
  const newFormHandler = () => {
    setActiveStep(0);
    setStepSuccess(false);
    setFixture(null);
    setCore(null);
    setDiameter("");
    setLength("");
    setRefCode("");
    setLotNumber("");
    setTooth(null);
    setSurgeonFirstName("");
    setSurgeonLastName("");
    setSurgeryDate("");
    setFailureDate("");
    setBeforeXrayImage("");
    setAfterXrayImage("");
    setNewFixture(null);
    setNewCore(null);
    setNewDiameter("");
    setNewLength("");
    setNewRefCode("");
    setReason(null);
    setReasonText("");

    setPatientCode("");
    setPatientAge("");
    setPatientGender("");
    setPatientRisks("");
    setPatientHygiene("");
  };

  const handleAddressSelect = (selectedAddress) => {
    const { street, city, zip, country, state } = selectedAddress;

    // Set each part of the address to state
    setAddress({
      street: street || "",
      city: city || "",
      zip: zip || "",
      state: state || "",
      country: country || "",
      display: "",
    });

    // Build the display string from parts that are not empty
    const parts = [street, city, state, zip, country].filter(Boolean);
    const displayString = parts.join(", ");

    // Set the display address
    setDisplayAddress(displayString);
    // setHasChanged(true);
  };

  const toggleManualAddressVisible = () => {
    setManualAddressVisible(!manualAddressVisible);
  };

  //STEPS
  const steps = [
    {
      label: t("implantForm.step0Title"),
      description: t("implantForm.step0Text"),
      icon: FaUserCircle,
    },
    {
      label: t("implantForm.step1Title"),
      description: t("implantForm.step1Text"),
      icon: TbDental,
    },
    {
      label: t("implantForm.step2Title"),
      description: t("implantForm.step2Text"),
      icon: TbDentalBroken,
    },
    {
      label: t("implantForm.step3Title"),
      description: t("implantForm.step3Text"),
      icon: TbUserCircle,
    },
    {
      label: t("implantForm.step4Title"),
      description: t("implantForm.step4Text"),
      icon: TbArrowsLeftRight,
    },
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [stepSuccess, setStepSuccess] = useState(false);

  const [isEmployee, setIsEmployee] = useState(false);

  // USE EFFECT
  useEffect(() => {
    if (userInfo) {
      setIsLoggedIn(true);
      setUserId(userInfo._id);
      setClientType(userInfo.clientType);
      setEmail(userInfo.email);
      setFirstName(userInfo.firstName);
      setLastName(userInfo.lastName);
      setCompanyName(userInfo.companyName);
      setCompanyCode(userInfo.companyCode);
      setAuthor(userInfo._id);
      setAuthorType("client");
    } else {
      setIsLoggedIn(false);
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo && userInfo.address && userInfo.address.length > 0) {
      const userAddress = userInfo.address[0];
      setAddress({
        street: userAddress.street,
        city: userAddress.city,
        zip: userAddress.zip,
        state: userAddress.state,
        country: userAddress.country,
        display: "",
      });

      setDisplayAddress(
        `${userAddress.street}, ${userAddress.city}, ${userAddress.zip}, ${userAddress.country}`
      );
    } else {
      setAddress({
        street: "",
        city: "",
        zip: "",
        state: "",
        country: "",
        display: "",
      });
      setDisplayAddress("");
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo && userInfo.role === "employee") {
      setIsEmployee(true);
    } else {
      setIsEmployee(false);
    }
  }, [userInfo]);

  useEffect(() => {
    if (success && trackingNumber) {
      console.log("trackingNumber", trackingNumber);
      setUserTrackingNumber(trackingNumber);
      setStepSuccess(true);
      toast({
        title: t("implantForm.successTitle"),
        description: t("implantForm.successText"),
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      setFixture(null);
      setCore(null);
      setDiameter("");
      setLength("");
      setRefCode("");
      setLotNumber("");
      setTooth(null);
      setSurgeonFirstName("");
      setSurgeonLastName("");
      setSurgeryDate("");
      setFailureDate("");
      setBeforeXrayImage("");
      setAfterXrayImage("");
      setNewFixture(null);
      setNewCore(null);
      setNewDiameter("");
      setNewLength("");
      setNewRefCode("");
      setReason(null);
      setReasonText("");
      setPatientCode("");
      setPatientAge("");
      setPatientGender("");
      setPatientRisks("");
      setPatientHygiene("");
      dispatch({ type: REPORT_IMPLANT_FAILURE_CREATE_RESET });
    } else if (errorCreate) {
      toast({
        title: t("implantForm.errorTitle"),
        description: t("implantForm.errorText"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [success, errorCreate]);

  // STEPS TOUCHED
  const [isStep3Touched, setIsStep3Touched] = useState(false);

  // STEPS VALIDATION

  //step 0
  const isFirstNameValid = firstName !== "";
  const isLastNameValid = lastName !== "";
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const isEmailValid = isValidEmail(email);
  const isCompanyNameValid = companyName !== "";
  const isCompanyCodeValid = companyCode !== "";

  //step1
  const isFixtureValid = fixture !== null;
  const isCoreValid = core !== null || core !== "";
  const isDiameterValid = diameter !== "" && diameter !== null;
  const isLengthValid = length !== "" && length !== null;
  const isLotNumberValid = lotNumber !== "" && lotNumber !== null;

  const isStreetValid = address.street !== "";
  const isCityValid = address.city !== "";
  const isCountryValid = address.country !== "";
  const isZipValid = address.zip !== "";

  const isAddressValid = isStreetValid && isCityValid && isCountryValid;

  //step2
  const isToothValid = tooth !== null;
  const isSurgeonFirstNameValid = surgeonFirstName !== "";
  const isSurgeonLastNameValid = surgeonLastName !== "";
  const isReasonValid = reason !== null;
  const isReasonTextValid =
    reason !== "Other" || (reason === "Other" && reasonText.trim() !== "");
  const isFailureDateValid = failureDate !== "";
  const isSurgeryDateValid = surgeryDate !== "";

  const isStep0ValidIndividual =
    isFirstNameValid && isLastNameValid && isEmailValid;

  const isStep0ValidCompany =
    isCompanyNameValid && isEmailValid && isCompanyCodeValid;

  const isBeforeXrayImageValid = beforeXrayImage !== "";
  const isAfterXrayImageValid = afterXrayImage !== "";

  //step3 - xray images
  // const isBeforeXrayImageValid = beforeXrayImage !== "";
  // const isAfterXrayImageValid = afterXrayImage !== "";

  //step3
  const isPatientCodeValid = patientCode !== "";
  const isPatientAgeValid = patientAge !== "";
  const isPatientGenderValid = patientGender !== "";
  const isPatientRisksValid = patientRisks !== "";
  const isPatientHygieneValid = patientHygiene !== "";

  //step4
  const isNewFixtureValid = newFixture !== null;
  const isNewCoreValid = newCore !== null || newCore !== "";
  const isNewDiameterValid = newDiameter !== "" && newDiameter !== null;
  const isNewLengthValid = newLength !== "" && newLength !== null;

  const clientTypeStep0 =
    clientType === "individual" ? isStep0ValidIndividual : isStep0ValidCompany;

  // steps validation
  const isStep0Valid = clientTypeStep0 && isAddressValid;

  const isStep1Valid =
    isFixtureValid &&
    isCoreValid &&
    isDiameterValid &&
    isLengthValid &&
    isLotNumberValid;

  const isStep2Valid =
    isToothValid &&
    isSurgeonFirstNameValid &&
    isSurgeonLastNameValid &&
    isFailureDateValid &&
    isSurgeryDateValid &&
    isReasonValid &&
    isReasonTextValid &&
    isBeforeXrayImageValid &&
    isAfterXrayImageValid;

  // const isStep3Valid = isBeforeXrayImageValid && isAfterXrayImageValid;
  // const isStep3Valid = true;
  const isStep3Valid =
    isPatientAgeValid &&
    isPatientGenderValid &&
    isPatientRisksValid &&
    isPatientHygieneValid;

  const isStep4Valid =
    isNewFixtureValid &&
    isNewCoreValid &&
    isNewDiameterValid &&
    isNewLengthValid;

  const stepValidations = [
    isStep0Valid,
    isStep1Valid,
    isStep2Valid,
    isStep3Valid,
    isStep4Valid,
    // Add other step validations here
  ];

  const handleStepClick = (index) => {
    if (isLoggedIn && !isEmployee) {
      setActiveStep(index);
    } else if (isEmployee) {
      // do nothing
    } else {
    }
  };

  // USE EFFECT

  useEffect(() => {
    //scroll to top
    window.scrollTo(0, 0);
  }, []);

  // LOGS
  console.log("newRefCode", newRefCode);

  // console.log("userInfo", userInfo);
  // console.log("address", address);
  // console.log("displayAddress", displayAddress);

  //RENDER
  return (
    <>
      <Helmet>
        <title>{t("pageTitle.implantForm")}</title>
        <meta name="description" content={t("pageContent.implantForm")} />
      </Helmet>

      <Header />
      <Flex w="100%" direction="column" p="100px 0 0">
        <Flex
          w="100%"
          maxW={["100%", "100%", "1200px"]}
          p={["0 0rem", "0 0rem", "0 0rem"]}
          direction="column"
          m={["0rem auto 0", "0rem auto 0", "4rem auto 0"]}
        >
          <Heading
            fontSize={["2rem", "2rem", "2.5rem"]}
            fontWeight="700"
            color="textPrimary"
            m={["0 0 1rem", "0 0 1rem", "0 0 1.5rem"]}
            p={["0 1.5rem", "0 1.5rem", "0"]}
          >
            {t("implantForm.reports")}
          </Heading>
          <Tabs index={0} colorScheme="blue">
            {!isMobile ? (
              <TabList border="none">
                {tabs.map((tab, index) => (
                  <Tab
                    key={index}
                    isDisabled={tab.disabled}
                    color={textSecondary}
                    borderBottom={`2px solid ${white}`}
                    p="1rem 2rem"
                    fontWeight="500"
                    borderTopLeftRadius="1rem"
                    borderTopRightRadius="1rem"
                    _hover={{
                      color: textPrimary,
                    }}
                    _selected={{
                      color: textPrimary,
                      borderBottom: `2px solid ${primary}`,

                      _focus: { boxShadow: "none" },
                    }}
                  >
                    {tab.title}
                  </Tab>
                ))}
              </TabList>
            ) : (
              <Flex p="0rem 1.5rem 0.5rem">
                {tabs.map((tab, index) => (
                  <Flex
                    isDisabled={tab.disabled}
                    color={white}
                    borderBottom={`2px solid ${white}`}
                    p="0.5rem 1rem"
                    fontWeight="500"
                    borderRadius="1rem"
                    bg={primary}
                    whiteSpace="nowrap"
                    align="center"
                    justify="center"
                    fontSize="sm"
                    // display="inline-block"
                    // onClick={() => handleTabChange(index)}
                    _hover={{
                      color: textPrimary,
                    }}
                  >
                    {tab.title}
                  </Flex>
                ))}
              </Flex>
            )}
          </Tabs>
          {/* <Flex
            w="100%"
            p="0 0rem"
            direction="column"
            m="0 auto 1.5rem"
            align="center"
            justify="center"
            maxW="1200px"
            flexShrink="0"
          ></Flex> */}
        </Flex>

        {isEmployee ? (
          <>
            <Flex direction="column" w="100%" bg={backgroundLight}>
              <Flex
                w="100%"
                p="0 0rem"
                direction="column"
                m="0 auto 0rem"
                align="center"
                justify="center"
                maxW={["100%", "100%", "1200px"]}
                flexShrink="0"
              >
                <Flex
                  w="100%"
                  h="100%"
                  direction="column"
                  p={["0rem 0 3rem", "0rem 0 3rem", "3rem 0 4rem"]}
                >
                  <Flex
                    bg={white}
                    justify="flex-start"
                    p="6rem 0rem 6rem"
                    h="100%"
                    // minH="600px"
                    borderRadius={["0rem", "0rem", "2rem"]}
                    border={`0px solid ${newBorder}`}
                    borderTop={`1px solid ${newBorder}`}
                    direction="column"
                  >
                    <Text
                      fontSize="2rem"
                      color={textSecondary}
                      fontWeight="500"
                      m="1rem auto 0.5rem"
                      textAlign="center"
                      maxW="600px"
                    >
                      {t("implantForm.isEmployee")}
                    </Text>
                    <Text
                      fontSize="1.25rem"
                      color={textSecondary}
                      textAlign="center"
                    >
                      {t("implantForm.employeeText")}
                    </Text>

                    <Flex align="center" justify="center" m="3rem 0 0">
                      <Button
                        size="lg"
                        fontSize="md"
                        bg={primary}
                        color={white}
                        fontWeight="500"
                        borderRadius="10rem"
                        minW="8rem"
                        onClick={() => {
                          navigate("/admin/reports/implant-failure-reports");
                        }}
                        _hover={{
                          bg: shade(0.3, primary),
                        }}
                      >
                        {t("landingMegagen.menuAdmin")}
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </>
        ) : (
          <Flex direction="column" w="100%" bg={backgroundLight}>
            <Flex
              w="100%"
              p="0 0rem"
              direction="column"
              m="0 auto 0rem"
              align="center"
              justify="center"
              maxW={["100%", "100%", "1200px"]}
              flexShrink="0"
            >
              <Flex
                w="100%"
                h="100%"
                direction="column"
                p={["0rem 0 3rem", "0rem 0 3rem", "3rem 0 4rem"]}
              >
                <Flex
                  bg={white}
                  justify="center"
                  // p="4rem 0rem 6rem"
                  h="100%"
                  // minH="100vh"
                  borderRadius={["0rem", "0rem", "2rem"]}
                  border={`0px solid ${newBorder}`}
                  borderTop={`1px solid ${newBorder}`}
                >
                  <Flex
                    // bg={backgroundLight}
                    maxW="100%"
                    w="100%"
                    borderRadius="3rem"
                    // overflow="hidden"
                    justify={["flex-end", "flex-end", "space-between"]}
                    minH={["643px", "643px", "initial"]}
                    direction={["column-reverse", "column-reverse", "row"]}
                  >
                    <Flex
                      w="30%"
                      minW="360px"
                      p={["0rem 0 0", "0rem", "3rem"]}
                      display={["none", "none", "flex"]}
                    >
                      <Flex
                        direction="column"
                        w="100%"
                        justify="space-between"
                        h="100%"
                      >
                        <Flex direction="column" w="100%">
                          {/* <Heading
                          fontSize="1.5rem"
                          color={textPrimary}
                          fontWeight="700"
                          mb="2rem"
                          lineHeight="1.5"
                        >
                          {t("implantForm.mainHeading")}
                        </Heading> */}
                          <Stack spacing="1rem">
                            {steps.map((step, index) => (
                              <Flex
                                key={index}
                                w="100%"
                                p="1rem 1rem"
                                bg={
                                  activeStep === index
                                    ? primary
                                    : backgroundLight
                                }
                                borderRadius="1rem"
                                cursor={
                                  isLoggedIn && !isEmployee
                                    ? "pointer"
                                    : "not-allowed"
                                }
                                // onClick={() => setActiveStep(index)}
                                onClick={
                                  isLoggedIn && !isEmployee
                                    ? () => handleStepClick(index)
                                    : null
                                }
                                align="center"
                                justify="space-between"

                                // isDisabled={activeStep < index}
                                // _disabled={{ opacity: "0.5", cursor: "not-allowed" }}
                              >
                                <Flex align="center" w="100%">
                                  <Icon
                                    as={step.icon}
                                    color={
                                      activeStep === index
                                        ? white
                                        : textSecondary
                                    }
                                    mr="2"
                                  />
                                  <Text
                                    fontSize="sm"
                                    color={
                                      activeStep === index
                                        ? white
                                        : textSecondary
                                    }
                                    fontWeight="500"
                                  >
                                    {step.label}
                                  </Text>
                                </Flex>
                                {/* Show check icon if the current step index is greater than the step being rendered */}
                                {stepValidations[index] && (
                                  <Icon
                                    as={FaCheck}
                                    color={
                                      activeStep === index ? white : "green"
                                    }
                                    fontSize="0.75rem"
                                  />
                                )}
                              </Flex>
                            ))}
                          </Stack>
                        </Flex>

                        <Flex w="100%" align="center" p="0 1rem">
                          <Icon
                            as={TbMessageCircleQuestion}
                            color={textSecondary}
                            fontSize="1.125rem"
                            mr="0.75rem"
                          />
                          <Stack gap="0rem">
                            {/* <Text fontSize="sm" color={textSecondary} fontWeight="500">
                      {t("implantForm.helpTitle")}
                    </Text> */}
                            <Text
                              fontSize="xs"
                              color={textSecondary}
                              fontWeight="500"
                            >
                              +370 605 60609
                            </Text>
                            <Link to="mailto:info@megagenbaltics.lt">
                              <Text
                                fontSize="xs"
                                color={textSecondary}
                                fontWeight="500"
                              >
                                info@megagenbaltics.lt
                              </Text>
                            </Link>
                          </Stack>
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex
                      w="100%"
                      bg={white}
                      p={["1.5rem 1.5rem", "1.5rem 1.5rem", "3rem"]}
                      direction="column"
                    >
                      {!stepSuccess ? (
                        <Flex w="100%" direction="column">
                          <Flex>
                            {activeStep === 0 ? (
                              <Stack
                                gap="0rem"
                                m="0 0 2rem"
                                maxW={["100%", "100%", "68%"]}
                              >
                                <Text
                                  fontSize="xs"
                                  color={textSecondary}
                                  fontWeight="500"
                                  display={["none", "none", "block"]}
                                >
                                  {activeStep + 1} / {steps.length}
                                </Text>
                                <Heading
                                  fontSize={["1.25rem", "1.25rem", "1.75rem"]}
                                  fontWeight="600"
                                  color={textPrimary}
                                  m="1rem 0 0.5rem"
                                >
                                  {steps[activeStep].label}
                                </Heading>
                                {clientType === "individual" ? (
                                  <Text
                                    fontSize="sm"
                                    fontWeight="500"
                                    color={textSecondary}
                                  >
                                    {t("implantForm.loggedInIndividual")}
                                    <Text as="span" color={primary}>
                                      {" "}
                                      {userInfo &&
                                        userInfo.firstName &&
                                        userInfo.firstName}{" "}
                                      {userInfo &&
                                        userInfo.lastName &&
                                        userInfo.lastName}
                                    </Text>
                                    . {t("implantForm.step1Text")}
                                  </Text>
                                ) : (
                                  <Text
                                    fontSize="sm"
                                    fontWeight="500"
                                    color={textSecondary}
                                  >
                                    {t("implantForm.loggedInCompany")}
                                    <Text as="span" color={primary}>
                                      {" "}
                                      {userInfo && userInfo.companyName
                                        ? userInfo.companyName
                                        : ""}
                                    </Text>
                                    . {t("implantForm.step1Text")}
                                  </Text>
                                )}
                              </Stack>
                            ) : (
                              <Stack gap="0rem" m="0 0 2rem">
                                <Text
                                  fontSize="xs"
                                  color={textSecondary}
                                  fontWeight="500"
                                >
                                  {activeStep + 1} / {steps.length}
                                </Text>
                                <Heading
                                  fontSize="1.75rem"
                                  fontWeight="600"
                                  color={textPrimary}
                                  m="1rem 0 0.5rem"
                                >
                                  {steps[activeStep].label}
                                </Heading>
                                <Text
                                  fontSize="sm"
                                  fontWeight="500"
                                  color={textSecondary}
                                  // minH="42px"
                                >
                                  {steps[activeStep].description}
                                </Text>
                              </Stack>
                            )}
                          </Flex>

                          {activeStep === 0 && (
                            <Step0
                              setActiveStep={setActiveStep}
                              userInfo={userInfo}
                              clientType={clientType}
                              firstName={firstName}
                              setFirstName={setFirstName}
                              lastName={lastName}
                              setLastName={setLastName}
                              companyName={companyName}
                              setCompanyName={setCompanyName}
                              companyCode={companyCode}
                              setCompanyCode={setCompanyCode}
                              email={email}
                              setEmail={setEmail}
                              isValid={isStep0Valid}
                              isLoggedIn={isLoggedIn}
                              isEmployee={isEmployee}
                              address={address}
                              setAddress={setAddress}
                              displayAddress={displayAddress}
                              setDisplayAddress={setDisplayAddress}
                              manualAddressVisible={manualAddressVisible}
                              toggleManualAddressVisible={
                                toggleManualAddressVisible
                              }
                              handleAddressSelect={handleAddressSelect}
                            />
                          )}

                          {activeStep === 1 && (
                            <Step1
                              handleFixtureChange={handleFixtureChange}
                              fixtureOptions={fixtureOptions}
                              setActiveStep={setActiveStep}
                              userInfo={userInfo}
                              fixture={fixture}
                              setFixture={setFixture}
                              core={core}
                              setCore={setCore}
                              diameter={diameter}
                              setDiameter={setDiameter}
                              length={length}
                              setLength={setLength}
                              refCode={refCode}
                              setRefCode={setRefCode}
                              lotNumber={lotNumber}
                              setLotNumber={setLotNumber}
                              setNewFixture={setNewFixture}
                              setNewCore={setNewCore}
                              setNewDiameter={setNewDiameter}
                              setNewLength={setNewLength}
                              isValid={isStep1Valid}
                            />
                          )}

                          {activeStep === 2 && (
                            <Step2
                              setActiveStep={setActiveStep}
                              userInfo={userInfo}
                              tooth={tooth}
                              setTooth={setTooth}
                              surgeonFirstName={surgeonFirstName}
                              setSurgeonFirstName={setSurgeonFirstName}
                              surgeonLastName={surgeonLastName}
                              setSurgeonLastName={setSurgeonLastName}
                              surgeryDate={surgeryDate}
                              setSurgeryDate={setSurgeryDate}
                              failureDate={failureDate}
                              setFailureDate={setFailureDate}
                              reason={reason}
                              setReason={setReason}
                              reasonText={reasonText}
                              setReasonText={setReasonText}
                              isValid={isStep2Valid}
                              beforeXrayImage={beforeXrayImage}
                              setBeforeXrayImage={setBeforeXrayImage}
                              afterXrayImage={afterXrayImage}
                              setAfterXrayImage={setAfterXrayImage}
                              trackingNumber={trackingNumber}
                            />
                          )}

                          {activeStep === 3 && (
                            <Step3
                              setActiveStep={setActiveStep}
                              setIsStep3Touched={setIsStep3Touched}
                              isValid={isStep3Valid}
                              patientCode={patientCode}
                              setPatientCode={setPatientCode}
                              patientAge={patientAge}
                              setPatientAge={setPatientAge}
                              patientGender={patientGender}
                              setPatientGender={setPatientGender}
                              patientRisks={patientRisks}
                              setPatientRisks={setPatientRisks}
                              patientHygiene={patientHygiene}
                              setPatientHygiene={setPatientHygiene}
                            />
                          )}

                          {activeStep === 4 && (
                            <Step4
                              handleNewFixtureChange={handleNewFixtureChange}
                              fixtureOptions={fixtureOptions}
                              newFixture={newFixture}
                              setNewFixture={setNewFixture}
                              newCore={newCore}
                              setNewCore={setNewCore}
                              newDiameter={newDiameter}
                              setNewDiameter={setNewDiameter}
                              newLength={newLength}
                              setNewLength={setNewLength}
                              setStepSuccess={setStepSuccess}
                              fixture={fixture}
                              core={core}
                              diameter={diameter}
                              length={length}
                              newRefCode={newRefCode}
                              setNewRefCode={setNewRefCode}
                              lotNumber={lotNumber}
                              handleSubmit={handleSubmit}
                              isValid={isStep4Valid}
                              isStep0Valid={isStep0Valid}
                              isStep1Valid={isStep1Valid}
                              isStep2Valid={isStep2Valid}
                              isStep3Valid={isStep3Valid}
                              isStep4Valid={isStep4Valid}
                              notes={notes}
                              setNotes={setNotes}
                            />
                          )}
                        </Flex>
                      ) : (
                        <Flex>
                          <StepSuccess
                            setActiveStep={setActiveStep}
                            userInfo={userInfo}
                            userTrackingNumber={userTrackingNumber}
                            newFormHandler={newFormHandler}
                          />
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        )}
        <Flex
          bg={white}
          borderTop={`1px solid ${newBorder}`}
          w="100%"
          p="3rem 0"
          borderBottom={`1px solid ${newBorder}`}
        >
          <Flex w="100%" maxW="1200px" m="0 auto">
            <Faq />
          </Flex>
        </Flex>

        <Footer />
      </Flex>
    </>
  );
};

export default ImplantFailForm;
