import React from "react";
import { Flex, Heading, Icon, Image, Text } from "@chakra-ui/react";
import { FaExchangeAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import TooltipText from "../../../../../components/partials/TooltipText";
import {
  backgroundLight,
  textSecondary,
  newBorder,
  textPrimary,
} from "../../../../../utils/colors";
import implant from "../../../../../assets/images/implant.png";
import { useMediaQuery } from "react-responsive";

const ReportExchangeInfo = ({ data }) => {
  const { t } = useTranslation();

  // MOBILE
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const exchangeArray = [
    {
      label: "Fixture",
      value: (
        <TooltipText
          icon="TbDental"
          label={t("implantForm.fixture")}
          value={t(`implants.${data?.fixture}`)}
          iconText
          noTooltip
        />
      ),
    },

    // Conditionally add the Core object based on its existence
    ...(data?.core
      ? [
          {
            label: "Core",
            value: (
              <TooltipText
                icon="TbMapPinStar"
                label={t("implantForm.core")}
                value={
                  data?.core === "regular" ||
                  data?.core === "deep" ||
                  data?.core === "special"
                    ? t(`core.${data?.core}`)
                    : data?.core
                }
                iconText
                noTooltip
              />
            ),
          },
        ]
      : []),

    {
      label: "Size",
      value: (
        <TooltipText
          icon="TbMapPin"
          label={t("implantForm.size")}
          value={"Ø" + data?.diameter + "mm / L" + data?.length}
          iconText
          noTooltip
        />
      ),
    },

    {
      label: "Ref",
      value: (
        <TooltipText
          icon="TbBuilding"
          label={t("implantForm.refCode")}
          value={data?.refCode}
          iconText
          noTooltip
        />
      ),
    },

    {
      label: "Lot",
      value: (
        <TooltipText
          icon="TbBuilding"
          label={t("implantFailureReports.lotNumberAlt")}
          value={data?.lotNumber}
          iconText
          maxW="120px"
          noTooltip
        />
      ),
    },
  ];

  const newFixtureArray = [
    {
      label: "Fixture",
      value: (
        <TooltipText
          icon="TbDental"
          label={t("implantForm.fixture")}
          value={t(`implants.${data?.newFixture}`)}
          iconText
          noTooltip
        />
      ),
    },

    // Conditionally add the Core object based on its existence
    ...(data?.newCore
      ? [
          {
            label: "Core",
            value: (
              <TooltipText
                icon="TbMapPinStar"
                label={t("implantForm.core")}
                value={
                  data?.newCore === "regular" ||
                  data?.newCore === "deep" ||
                  data?.newCore === "special"
                    ? t(`core.${data?.newCore}`)
                    : data?.newCore
                }
                iconText
                noTooltip
              />
            ),
          },
        ]
      : []),

    {
      label: "Size",
      value: (
        <TooltipText
          icon="TbMapPin"
          label={t("implantForm.size")}
          value={"Ø" + data?.newDiameter + "mm / L" + data?.newLength}
          iconText
          noTooltip
        />
      ),
    },

    {
      label: "Ref",
      value: (
        <TooltipText
          icon="TbBuilding"
          label={t("implantForm.refCode")}
          value={data?.newRefCode}
          iconText
          noTooltip
        />
      ),
    },
  ];

  return (
    <Flex w="100%" direction="column">
      <Heading fontSize="md" fontWeight="600" color={textPrimary} m="0 0 1rem">
        {t("implantFailureReports.exchangeInfoTitle")}
      </Heading>

      <Flex align="center" gap="2rem" direction={["column", "row"]}>
        <Flex
          border={`1px solid ${newBorder}`}
          borderRadius="1rem"
          align="center"
          justify="center"
          w={["100%", "180px"]}
          h={["200px", "180px"]}
          flexShrink="0"
        >
          <Image src={implant} alt="implantas" w={["20%", "30%"]} m="0 auto" />
        </Flex>
        <Flex direction="column" w="100%" p="0 0 1rem">
          <Flex align="center" direction={["column", "row"]}>
            <Flex direction="column" w="100%">
              {exchangeArray.map((item, index) => (
                <Flex key={index} alignItems="center">
                  {item.value && (
                    <Flex p="0.35rem 0" alignItems="center">
                      {item.value}
                    </Flex>
                  )}
                </Flex>
              ))}
            </Flex>
            {isMobile ? (
              <Flex
                w="100%"
                align="center"
                justify="center"
                p="1rem 1rem"
                m="1rem auto 1rem"
                borderTop={`1px solid ${newBorder}`}
                borderBottom={`1px solid ${newBorder}`}
                textAlign="center"
              >
                <Text fontSize="sm" color={textSecondary}>
                  {t("implantFailureReports.exchangingTo")}
                </Text>
              </Flex>
            ) : (
              <Flex
                align="center"
                p="1rem 1rem"
                m="0 3rem 0 1rem"
                bg={backgroundLight}
                borderRadius="10rem"
              >
                <Icon as={FaExchangeAlt} boxSize={3} color={textSecondary} />
              </Flex>
            )}
            <Flex direction="column" w="100%">
              {newFixtureArray.map((item, index) => (
                <Flex key={index} alignItems="center">
                  {item.value && (
                    <Flex p="0.35rem 0" alignItems="center">
                      {item.value}
                    </Flex>
                  )}
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ReportExchangeInfo;
